import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { HiFilter } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import Cookies from "js-cookie";
import { handleLogout } from "../../../redux/features/userSlice";
import axios from "axios";
import styles from "./Shop.module.scss";
import ShopAssigned from "./assigned/ShopAssigned";
import Pagination from "../../pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Shop() {
  const [owners, setOwners] = useState([]);
  const [allowners, setAllOwners] = useState([]);
  const [ownerID, setOwnerID] = useState("");
  const [filterType, setFilterType] = useState("");
  const [governorates, setGovernorates] = useState([]);
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const token = useSelector((state) => state.cpc.user.login.token);
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/reports/listOwners", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          setAllOwners(res.data.owners);
          if (filterType === "" && searchValue === "") {
            setTotal(res.data.owners.length);
            setOwners(res.data.owners);
            const sortedActivators = res.data.owners.sort((a, b) => {
              return b.userID - a.userID;
            });
          } else if (filterType === "activeOwners" && searchValue === "") {
            const filterOwners = res.data.owners.filter((owner) => {
              return owner.status == 1;
            });
            setTotal(filterOwners.length);
            setOwners(filterOwners);
            const sortedActivators = filterOwners.sort((a, b) => {
              return b.userID - a.userID;
            });
          } else if (filterType === "assignedOwners" && searchValue === "") {
            const filterOwners = res.data.owners.filter((owner) => {
              return owner["assigned_id"] !== 1 && owner.status === 0;
            });
            setTotal(filterOwners.length);
            setOwners(filterOwners);
            const sortedActivators = filterOwners.sort((a, b) => {
              return b.userID - a.userID;
            });
          } else if (searchValue !== "" && filterType === "") {
            const filterOwners = res.data.owners.filter((owner) => {
              return (
                (owner.businessName !== null &&
                  owner.businessName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) ||
                owner.firstName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                owner.lastName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                owner.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
                owner.email.toLowerCase().includes(searchValue.toLowerCase())
              );
            });
            setTotal(filterOwners.length);
            setOwners(filterOwners);
            const sortedActivators = filterOwners.sort((a, b) => {
              return b.userID - a.userID;
            });
          } else if (searchValue !== "" && filterType === "activeOwners") {
            const filterOwnerss = res.data.owners.filter((owner) => {
              return owner.status == 1;
            });
            const filterOwners = filterOwnerss.filter((owner) => {
              return (
                (owner.businessName !== null &&
                  owner.businessName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) ||
                owner.firstName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                owner.lastName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                owner.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
                owner.email.toLowerCase().includes(searchValue.toLowerCase())
              );
            });
            setTotal(filterOwners.length);
            setOwners(filterOwners);
            const sortedActivators = filterOwners.sort((a, b) => {
              return b.userID - a.userID;
            });
          } else if (searchValue !== "" && filterType === "assignedOwners") {
            const filterOwnerss = res.data.owners.filter((owner) => {
              return owner["assigned_id"] !== 1 && owner.status === 0;
            });
            const filterOwners = filterOwnerss.filter((owner) => {
              return (
                (owner.businessName !== null &&
                  owner.businessName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) ||
                owner.firstName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                owner.lastName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                owner.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
                owner.email.toLowerCase().includes(searchValue.toLowerCase())
              );
            });
            setTotal(filterOwners.length);
            setOwners(filterOwners);
          }
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [searchValue, filterType]);

  const navigate = useNavigate();

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + postsPerPage;
  const currentPosts = owners.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * postsPerPage) % owners.length;
    setItemOffset(newOffset);
    Cookies.set("page", event.selected);
  };

  useEffect(() => {
    if (Cookies.get("page")) {
      const newOffsett =
        (Number(Cookies.get("page")) * postsPerPage) % owners.length;
      setItemOffset(newOffsett);
    } else {
      setItemOffset(0);
    }
  });

  console.log(itemOffset, endOffset);

  const { t } = useTranslation();

  return (
    <div className={`${styles.adminShop} p-1`}>
      <Container>
        <Row>
          <Col xs={12} md={3} className="mb-3">
            <h3 className={`${styles.shopTitle}`}>{t("Merchants")}</h3>
          </Col>
          <Col xs={12} md={9}>
            <div
              className={`${styles.btnAssignProspect} d-flex justify-content-end`}
            >
              <Button
                variant="secondary"
                size="md"
                className={`${styles.reportBtn} me-2 mb-2`}
                onClick={() => {
                  navigate("/admin/dashboard/merchants/listing/report");
                }}
              >
                {t("listingMerchantsReport")}
              </Button>
              <Button
                variant="secondary"
                size="md"
                className={`${styles.reportBtn} me-2 mb-2`}
                onClick={() => {
                  navigate("/admin/dashboard/merchants/all/reports");
                }}
              >
                {t("reportMerchants")}
              </Button>

              <Button
                variant="primary"
                size="md"
                className={`${styles.assignProspectBtn} mb-2`}
                onClick={() => {
                  navigate("/admin/dashboard/merchants/all/addOwner");
                }}
              >
                {t("addMerchant")}
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
              <div className={`${styles.filterForm} mb-3 mb-lg-0`}>
                <Formik
                  initialValues={{ filterType: "" }}
                  onSubmit={(values) => {
                    setFilterType(values.filterType);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.filterInput}`}>
                          <InputGroup.Text className={`${styles.filterLabel}`}>
                            <HiFilter />
                          </InputGroup.Text>
                          <Form.Select
                            size="sm"
                            name="filterType"
                            id="filterType"
                            onChange={(e) => {
                              setFieldValue(
                                "filterType",
                                e.currentTarget.value
                              );
                              handleSubmit();
                            }}
                            className={`${styles.filterSelect}`}
                            value={values.filterType}
                          >
                            <option value="">{t("allMerchants")}</option>
                            <option value="activeOwners">
                              {t("activatedMerchants")}
                            </option>
                            <option value="assignedOwners">
                              {t("assignedMerchants")}
                            </option>
                          </Form.Select>
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className={`${styles.searchForm}`}>
                <Formik
                  initialValues={{ searchValue: "" }}
                  onSubmit={(values) => {}}
                >
                  {({ values, handleChange, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.searchInput}`}>
                          <InputGroup.Text className={`${styles.searchLabel}`}>
                            <BiSearch />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("merchantSearch")}
                            className={`${styles.searchField}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => {
                              setItemOffset(0);
                              setSearchValue(e.currentTarget.value);
                            }}
                          />
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3">
            <div className={`${styles.shopContent}`}>
              <ShopAssigned owners={currentPosts} />
            </div>
          </Col>
          <Col xs={12}>
            <Pagination
              totalPosts={total}
              postsPerPage={postsPerPage}
              onChangePage={handlePageClick}
              currentPage={itemOffset}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
