import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const userForgetpass = createAsyncThunk(
  "user/forget",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/forgotPassword/sendCode",
        {
          email: data.email,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const userAcceptotp = createAsyncThunk(
  "admin/verify",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/forgotPassword/resetPassword",
        {
          otp: info.otp,
          newPassword: info.newPassword,
          email: info.email,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const ownerSendOTP = createAsyncThunk(
  "ownerTrader/send/otp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://evouchers.shareecoin.com/CPC/customer/sendOTP`,
        null,
        {
          params: {
            mobileNumber: data.mobileNumber,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "reset/password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://evouchers.shareecoin.com/CPC/customer/updatePassword",
        {
          mobileNumber: data.phoneNumber,
          OTP: data.otp,
          newPassword: data.password,
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const userLogin = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/user/login",
        {
          email: data.email,
          password: data.password,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const ownerLogin = createAsyncThunk(
  "owner/login",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/customer/login",
        {
          phone: info.phone,
          password: info.password,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const userRegister = createAsyncThunk(
  "user/register",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/register/owner",
        {
          firstName: info.firstName,
          lastName: info.lastName,
          phone: info.phoneNumber,
          email: info.email,
          NID: info.nationalID,
          districts: [
            {
              govID: info.govID,
              cityID: info.cityID,
            },
          ],
          businessName: info.businessName,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const customerLoginInfo = createAsyncThunk(
  "customer/login",
  async (info, { rejectWithValue }) => {
    console.log(info);
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/customer/login",
        {
          phone: info.phone,
          password: info.password,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const stationOwnerLogin = createAsyncThunk(
  "station/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/stationOwnerLogin",
        {
          email: data.email,
          password: data.password,
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const stationOwnerForgetPassword = createAsyncThunk(
  "stationOwner/forgetpassword",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/forgotPassword",
        {
          phone: data.phoneNumber,
          email: data.email,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  login: {
    token: "",
    roleName: "",
    userID: "",
    businessName: "",
    cityNameEn: "",
    goverNameEn: "",
    cityNameAr: "",
    goverNameAr: "",
    merchantID: "",
    OwnerTraderPhoneNumber: "",
    activatorFirstName: "",
    customerPhoneNumber: "",
    stationFirstName: "",
    stationLastName: "",
    stationEmail: "",
    stationOwnerPhoneNumber: "",
    stationPointsBalance: "",
    pathStatus: false,
    paymentInfo: {
      operatorID: "",
      operatorPhoneNumber: "",
      stationID: "",
      stationName: "",
      govID: "",
      cityID: "",
    },
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeWhatsIcon: (state, action) => {
      state.login.pathStatus = action.payload.status;
    },
    handleLogout: (state, action) => {
      state.login.token = "";
      state.login.roleName = "";
      state.login.businessName = "";
      state.login.pathStatus = false;
      state.login.cityNameAr = "";
      state.login.goverNameAr = "";
      state.login.cityNameEn = "";
      state.login.goverNameEn = "";
      state.login.merchantID = "";
      state.login.OwnerTraderPhoneNumber = "";
      state.login.userID = "";
      state.login.activatorFirstName = "";
      state.login.stationFirstName = "";
      state.login.stationLastName = "";
      state.login.stationEmail = "";
      state.login.stationOwnerPhoneNumber = "";
      state.login.stationPointsBalance = "";
      state.login.paymentInfo.operatorID = "";
      state.login.paymentInfo.operatorPhoneNumber = "";
      state.login.paymentInfo.stationID = "";
      state.login.paymentInfo.stationName = "";
      state.login.paymentInfo.govID = "";
      state.login.paymentInfo.cityID = "";
    },
  },
  extraReducers: {
    [userLogin.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        if (action.payload.data.roleName === "Activator") {
          state.login.roleName = action.payload.data.roleName;
          state.login.token = action.payload.data.token;
          state.login.userID = action.payload.data.userId;
          state.login.activatorFirstName = action.payload.data.firstName;
        } else {
          state.login.roleName = action.payload.data.roleName;
          state.login.token = action.payload.data.token;
        }
      }
    },
    [ownerLogin.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        console.log(action);
        state.login.roleName = action.payload.data.roleName;
        state.login.OwnerTraderPhoneNumber = action.meta.arg.phone;
        state.login.token = action.payload.data.token;
        state.login.businessName = action.payload.data.businessName;
        state.login.cityNameEn = action.payload.data["city_name_en"];
        state.login.cityNameAr = action.payload.data["city_name_ar"];
        state.login.goverNameEn = action.payload.data["governorate_name_en"];
        state.login.goverNameAr = action.payload.data["governorate_name_ar"];
        state.login.merchantID = action.payload.data.userId;
      }
    },
    [customerLoginInfo.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.login.customerPhoneNumber = action.meta.arg.phone;
        state.login.roleName = action.payload.data.roleName;
        state.login.token = action.payload.data.token;
        state.login.businessName = action.payload.data.businessName;
        state.login.cityNameEn = action.payload.data["city_name_en"];
        state.login.cityNameAr = action.payload.data["city_name_ar"];
        state.login.goverNameEn = action.payload.data["governorate_name_en"];
        state.login.goverNameAr = action.payload.data["governorate_name_ar"];
        state.login.merchantID = action.payload.data.userId;
        state.login.paymentInfo.operatorID = action.meta.arg.operator_id;
        state.login.paymentInfo.operatorPhoneNumber =
          action.meta.arg.operator_phoneNumber;
        state.login.paymentInfo.stationID = action.meta.arg.station_id;
        state.login.paymentInfo.stationName = action.meta.arg.station_name;
        state.login.paymentInfo.govID = action.meta.arg.gov_id;
        state.login.paymentInfo.cityID = action.meta.arg.city_id;
      }
    },
    [stationOwnerLogin.fulfilled]: (state, action) => {
      console.log(action);
      if (action.payload.data.statusCode === 200) {
        state.login.token = action.payload.data.data.token;
        state.login.roleName = "stationOwner";
        state.login.stationFirstName = action.payload.data.data.firstName;
        state.login.stationLastName = action.payload.data.data.lastName;
        state.login.stationOwnerPhoneNumber = action.payload.data.data.phone;
        state.login.stationEmail = action.payload.data.data.email;
        state.login.stationPointsBalance =
          action.payload.data.data.pointsBalance;
        state.login.userID = action.payload.data.data.id;
      }
    },
  },
});

export const { handleLogout, removeWhatsIcon } = userSlice.actions;

export default userSlice.reducer;
