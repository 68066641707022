import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const createOperator = createAsyncThunk(
  "/add/operator",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/create/operator",
        {
          name: data.operatorName,
          phone_number: data.operatorPhoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const activateOperator = createAsyncThunk(
  "/activate/operator",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `https://evouchers.shareecoin.com/CPC/fuelStation/activate/operator/${data.operatorID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deactivateOperator = createAsyncThunk(
  "/deactivate/operator",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.delete(
        `https://evouchers.shareecoin.com/CPC/fuelStation/deactivate/operator/${data.operatorID}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createPDFQrCode = createAsyncThunk(
  "/create/pdfQrcode",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `https://evouchers.shareecoin.com/CPC/fuelStation/generateQRCode/${data.operatorID}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {};

export const stationSlice = createSlice({
  name: "station",
  initialState,
  reducers: {},
  extraReducers: {
    [createOperator.fulfilled]: (state, action) => {},
    [activateOperator.fulfilled]: (state, action) => {},
  },
});

export const {} = stationSlice.actions;

export default stationSlice.reducer;
