import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Popover,
  OverlayTrigger,
  Badge,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./Assigned.module.scss";
import { handleLogout } from "../../../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";

export default function Assigned() {
  const [assignedShops, setAssignedShops] = useState([]);
  const [result, setResult] = useState([]);

  const token = useSelector((state) => state.cpc.user.login.token);

  const { activatorID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listActivators", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const targetActivator = res.data.activators.filter((activ) => {
            return activ.activatorId == activatorID;
          });
          targetActivator.map((activator) => {
            const assignedShops = activator.assignedMerchants.filter(
              (assign) => {
                return assign.activationStatus === 0;
              }
            );
            setAssignedShops(assignedShops);
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  const { t } = useTranslation();

  console.log(assignedShops);

  return (
    <div className={`${styles.assignedContent} p-2`}>
      {assignedShops.length !== 0 ? (
        assignedShops.map((merchant, index) => {
          return (
            <div
              className={`${styles.bgGrey} d-flex justify-content-between align-items-center p-4 w-100 mb-3`}
              key={index}
            >
              <div className={`${styles.infoContent}`}>
                <h5>{merchant["businessName"]}</h5>
                <span>{`${t("ownerName")}: ${merchant["ownerFirstName"]} ${
                  merchant["ownerLastName"]
                }`}</span>
              </div>
              <div>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={
                    <Popover
                      id={`popover-positioned-right`}
                      className={`${styles.branchContentParent}`}
                    >
                      <Popover.Body className={`${styles.branchContentBody}`}>
                        <ul className="m-0 p-0">
                          {merchant["branches"].map((branch, n) => {
                            return (
                              <li key={n}>{`${branch[t("governReport")]}, ${
                                branch[t("cityReport")]
                              }`}</li>
                            );
                          })}
                        </ul>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button
                    variant="primary"
                    className={`${styles.branchContent}`}
                    size="sm"
                  >
                    {`${merchant.branches.length} ${t("branches")}`}
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          );
        })
      ) : (
        <div className={`${styles.bgGrey} p-4 w-100 mb-3 text-center`}>
          <h4>{t("noAssignedMerchants")}</h4>
        </div>
      )}
    </div>
  );
}
