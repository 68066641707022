import React from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./StationInfo.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function StationInfo(props) {
  console.log(props.stationinfo);

  const station = props.stationinfo;
  const { t } = useTranslation();

  if (props.stationinfo != "") {
    return (
      <div className={`${styles.customerInfoContent}`}>
        <Modal {...props} size="sm" centered>
          <Modal.Body className={`${styles.giftPointBody}`}>
            <p className="my-1">{station["station_name"]}</p>
            <p className="my-1">
              {i18next.language == "ar"
                ? station.governorate["governorate_name_ar"]
                : station.governorate["governorate_name_en"]}
              ,{" "}
              {i18next.language == "ar"
                ? station.city["city_name_ar"]
                : station.city["city_name_en"]}
            </p>
            <h4 className="mt-3 mb-2">Operator Information</h4>
            <p className="my-1">{station.operator["name"]}</p>
            <p className="my-1">{station.operator["phone_number"]}</p>
            <div className="d-grid mt-3">
              <Button
                size="sm"
                variant="priamry"
                className={`${styles.okBtn}`}
                onClick={() => {
                  props.onHide();
                }}
              >
                {t("ok")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
