import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Container, Row, Col, Button, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./OwnerBalance.module.scss";
import { BsGift } from "react-icons/bs";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { BsQrCodeScan } from "react-icons/bs";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { earnPointsAfterScanQr } from "../../../redux/features/ownerSlice";
import { handleLogout } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import OwnerQrscan from "./qrscan/OwnerQrscan";
import OwnerPoint from "./point/OwnerPoint";

export default function OwnerBalance() {
  const [balance, setBalance] = useState("");
  const [showQrModal, setShowQrModal] = useState(false);
  const [traderBalance, setTraderBalance] = useState([]);
  const token = useSelector((state) => state.cpc.user.login.token);
  const [points, setPoints] = useState("");
  const [showGift, setShowGift] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/customer/pointsBalance", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          setTraderBalance(res.data.traderPointsBalances);
          setBalance(res.data.totalPointsBalance);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  const { t, i18n } = useTranslation();

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const voucherCodeValidaiton = yup.object().shape({
    voucherCode: yup.string().required("voucherCodeValidation"),
  });

  return (
    <div
      className={`${styles.OwnerBalanceContent}`}
      dir={document.body.dir === "rtl" ? "rtl" : "ltr"}
    >
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              {t("branchesBalance")}
            </h3>
          </Col>
          <Col xs={12} md={6}>
            <div className={`${styles.btnRight}`}>
              <Button
                size="md"
                variant="primary"
                onClick={() => {
                  navigate("/owner/dashboard/balance/report");
                }}
                className="mx-1 mb-2"
              >
                {t("report")}
              </Button>
              <Button
                size="md"
                variant="primary"
                onClick={() => {
                  navigate("/owner/dashboard/balance/redeemPoints");
                }}
                className="mx-1 mb-2"
              >
                <BsGift className="me-2" />
                {t("redeemPoints")}
              </Button>
            </div>
          </Col>

          <Col xs={12} md={{ span: 8, offset: 2 }} className="mt-4 mb-3">
            <div
              className={`${styles.totalBalancePoints} mb-3 d-flex justify-content-center align-items-center flex-wrap`}
            >
              <p className="mb-0">{t("ownerTotalPointsBalance")} </p>
              <span className="mx-2 py-2 px-3">{balance}</span>
            </div>
            <div className={`${styles.balanceTableContent}`}>
              <Table responsive borderless>
                <thead className="text-center">
                  <tr>
                    <th>{t("branchName")}</th>

                    <th>{t("points")}</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {traderBalance.map((bal, index) => {
                    return (
                      <Fragment key={index}>
                        <tr>
                          <td>{bal["branchName"]}</td>
                          <td>{bal["pointsBalance"]}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col xs={12} md={{ span: 8, offset: 2 }} className="mb-4">
            <div
              className={`${styles.scanQrContent} p-3 p-md-5 w-100 d-flex flex-column justify-content-center align-items-center`}
            >
              <h6 className={`${styles.scanTitle} mb-4`}>{t("scanQrCode")}</h6>
              <Button
                variant="light"
                className={`${styles.scanBtn}`}
                onClick={() => {
                  setShowQrModal(true);
                }}
              >
                <BsQrCodeScan />
              </Button>
            </div>
          </Col>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <div
              className={`${styles.scanQrContent} p-3 p-md-5 w-100 d-flex flex-column justify-content-center align-items-center`}
            >
              <h6 className={`${styles.scanTitle} mb-4`}>
                {t("codeManually")}
              </h6>
              <Formik
                initialValues={{
                  voucherCode: "",
                }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    earnPointsAfterScanQr({
                      qrCode: values.voucherCode,
                      token,
                    })
                  ).then((res) => {
                    const loadReq = toast.loading(t("loading"), {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    if (res.payload.data.statusCode === 200) {
                      setPoints(res.payload.data.addedPoints);
                      setShowGift(true);
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={voucherCodeValidaiton}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => checkKeyDown(e)}
                    >
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          className={`${styles.verifyInput}`}
                          onChange={handleChange}
                          name="voucherCode"
                          id="voucherCode"
                          value={values.voucherCode}
                        />
                        {errors.voucherCode && touched.voucherCode && (
                          <Form.Text className="text-error">
                            {t(errors.voucherCode)}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="primary"
                          type="submit"
                          className={`${styles.verifyBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("checkCodeBtn")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
      <OwnerQrscan
        show={showQrModal}
        onHide={() => {
          setShowQrModal(false);
        }}
      />
      <OwnerPoint
        show={showGift}
        onHide={() => {
          setShowGift(false);
        }}
        points={points}
      />
    </div>
  );
}
