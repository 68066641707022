import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import * as yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  adminUpdateActivator,
  handleCityofGovern,
} from "../../../../redux/features/adminSlice";
import { handleLogout } from "../../../../redux/features/userSlice";
import { IoMdArrowRoundBack } from "react-icons/io";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import styles from "./EditActivator.module.scss";
import { useTranslation } from "react-i18next";

export default function EditActivator() {
  const [cities, setCities] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [governID, setGovernID] = useState(null);
  const [activeCity, setAcitveCity] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const [selectList, setSelectList] = useState([]);
  const [selectCity, setSelectCity] = useState([]);

  const multiSelectRef = useRef();

  const handleResetMultiSelect = () => {
    multiSelectRef.current.resetSelectedValues();
  };

  const handleRemoveSelectedItem = (value) => {
    multiSelectRef.current.onRemoveSelectedItem(value);
  };

  const userID = useParams().activatorID;

  const [activator, setActivator] = useState([]);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/reports/listUsers-modified", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const filteredAct = res.data.data.filter((act) => {
            return act.userID == userID;
          });
          setActivator(filteredAct);
          filteredAct.map((act) => {
            let arrayDistricts = [];
            let arrayOfDefaultDistricts = [];
            act.districts.map((dis) => {
              arrayDistricts.push({
                govID: act.districts[0]["governorate_id"],
                cityID: dis["city_id"],
              });
              arrayOfDefaultDistricts.push({
                cityID: dis["city_id"],
                city_name_en: dis.city["city_name_en"],
                city_name_ar: dis.city["city_name_ar"],
              });
            });
            const loadSaveData = {
              firstName: act.firstName,
              lastName: act.lastName,
              phoneNumber: act.phone,
              regNumber: act.regNumber,
              email: act.email,
              govID: act.districts[0]["governorate_id"],
              districts: arrayDistricts,
              defaultDistricts: arrayOfDefaultDistricts,
            };
            setFormValues(loadSaveData);
            setGovernID(act.districts[0]["governorate_id"]);
            setSelectList(arrayOfDefaultDistricts);
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGoverns(res.data["Governates List"]);
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: governID,
        Function: "ListCites",
      })
      .then((res) => {
        setCities(res.data["List of Cities"]);
      });
  }, [governID]);

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    regNumber: "",
    email: "",
    govID: "",
    districts: [],
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.cpc.user.login.token);

  const { t, i18n } = useTranslation();

  return (
    <div className={`${styles.editActivator} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/team");
                }}
              />
              {t("editActivator")}
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={formValues || initialValues}
            onSubmit={(values) => {
              const loadReq = toast.loading(t("loading"), {
                transition: Zoom,
                position:
                  i18n.language === "ar" ? "bottom-right" : "bottom-left",
              });
              dispatch(
                adminUpdateActivator({
                  token,
                  activatorID: userID,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phoneNumber: values.phoneNumber,
                  email: values.email,
                  nationalID: values.regNumber,
                  districts: values.districts,
                })
              ).then((res) => {
                if (res.payload.data.statusCode === 200) {
                  successMessage(
                    res.payload.data.message,
                    res.payload.data.messageAr,
                    loadReq
                  );
                  navigate("/admin/dashboard/team");
                } else {
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                }
              });
            }}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form id="editActivatorForm" onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("firstName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("firstName")}
                        className={`${styles.addInput}`}
                        name="firstName"
                        id="firstName"
                        onChange={(e) => {
                          setFieldValue("firstName", e.currentTarget.value);
                        }}
                        value={values.firstName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("lastName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className={`${styles.addInput}`}
                        name="lastName"
                        id="lastName"
                        onChange={(e) => {
                          setFieldValue("lastName", e.currentTarget.value);
                        }}
                        value={values.lastName}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("phoneNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={11}
                        placeholder={t("phoneNumber")}
                        className={`${styles.addInput}`}
                        name="phoneNumber"
                        id="phoneNumber"
                        onChange={(e) => {
                          setFieldValue("phoneNumber", e.currentTarget.value);
                        }}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.phoneNumber}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("regNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={6}
                        placeholder={t("regNumber")}
                        className={`${styles.addInput}`}
                        name="regNumber"
                        id="regNumber"
                        onChange={(e) => {
                          setFieldValue("regNumber", e.currentTarget.value);
                        }}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.regNumber}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("email")}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className={`${styles.addInput}`}
                        name="email"
                        id="email"
                        onChange={(e) => {
                          setFieldValue("email", e.currentTarget.value);
                        }}
                        value={values.email}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("gover")}
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="govID"
                        id="govID"
                        onChange={(e) => {
                          setGovernID(e.currentTarget.value);
                          setFieldValue("govID", e.currentTarget.value);
                          handleResetMultiSelect();
                          setSelectList([]);
                          setAcitveCity(true);
                        }}
                        value={values.govID}
                      >
                        <option value="">{t("selectGover")}</option>
                        {governs.map((gov) => {
                          return (
                            <option
                              key={gov.GovernateID}
                              value={gov.GovernateID}
                            >
                              {gov[t("govers")]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("districts")}
                      </Form.Label>
                      <Multiselect
                        className={`${styles.addMultiSelect}`}
                        ref={multiSelectRef}
                        selectedValues={values.defaultDistricts}
                        customCloseIcon={<AiFillCloseCircle className="ms-1" />}
                        options={cities}
                        displayValue={
                          i18n.language == "ar"
                            ? "city_name_Ar"
                            : "city_name_en"
                        }
                        placeholder={t("selectDistricts")}
                        name="cityIDs"
                        id="cityIDs"
                        onSelect={(selectedList, selectedItem) => {
                          setSelectList(selectedList);
                          let selArray = [];
                          selectedList.map((sel) => {
                            selArray.push({
                              govID: parseInt(governID),
                              cityID: sel.cityID,
                            });
                          });
                          setFieldValue("districts", selArray);
                        }}
                        onRemove={(selectedList, selectedItem) => {
                          setSelectList(selectedList);
                          let selArray = [];
                          selectedList.map((sel) => {
                            selArray.push({
                              govID: parseInt(governID),
                              cityID: sel.cityID,
                            });
                          });
                          setFieldValue("districts", selArray);
                        }}
                      />
                      <div className="mt-3">
                        {selectList.map((sel, i) => {
                          return (
                            <span className={`${styles.chipCustom}`} key={i}>
                              {i18n.language == "ar" ? (
                                <>
                                  {" "}
                                  <i
                                    className={`${styles.closeIcon} mx-1`}
                                    onClick={() => {
                                      handleRemoveSelectedItem(sel);
                                    }}
                                  >
                                    <IoMdClose />
                                  </i>
                                  {sel["city_name_ar"]}
                                </>
                              ) : (
                                <>
                                  <i
                                    className={`${styles.closeIcon} ms-1`}
                                    onClick={() => {
                                      handleRemoveSelectedItem(sel);
                                    }}
                                  >
                                    <IoMdClose />
                                  </i>
                                  {sel["city_name_en"]}
                                </>
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </Col>
                    <Col xs={12} className="mt-3 text-center">
                      <Button
                        type="submit"
                        size="md"
                        variant="primary"
                        form="editActivatorForm"
                        className={`${styles.updateBtn}`}
                      >
                        {t("update")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}
