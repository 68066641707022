import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiDeleteBin6Line, RiDownload2Fill } from "react-icons/ri";
import Multiselect from "multiselect-react-dropdown";
import { NavLink, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import axios from "axios";
import styles from "./AddCampaign.module.scss";
import { RiListSettingsFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import "./addCampaignStyle.css";
import Cashout from "./cashout/Cashout";
import Freeunit from "./freeunit/Freeunit";
import Vouch from "./vouch/Vouch";

export default function AddCampaign() {
  const [products, setProducts] = useState([]);
  const [earningType, setEarnigType] = useState([]);
  const [rewardType, setRewardType] = useState([]);

  const [earnValue, setEarnValue] = useState("");
  const [earn, setEarn] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);

  const [items, setItems] = useState([]);

  const [rewardID, setRewardID] = useState("");

  const [showReward, setShowReward] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [cashoutValue, setCashoutValue] = useState("");
  const [unitValue, setUnitValue] = useState("");

  const [productIDs, setProductsIDs] = useState([]);
  const [campaign, setCampaign] = useState({
    itemsIds: [],
    earnId: "",
    rewardId: "",
  });

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listProducts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setProducts(res.data.data);
      });
    axios
      .get("https://evouchers.shareecoin.com/CPC/listRewardTypes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setRewardType(res.data.data);
        const vouchValue = res.data.data.filter((val) => {
          return val["id"] == 1;
        });
        const unitValue = res.data.data.filter((val) => {
          return val["id"] == 2;
        });
        const cashValue = res.data.data.filter((val) => {
          return val["id"] == 3;
        });
        setDiscountValue(vouchValue);
        setCashoutValue(cashValue);
        setUnitValue(unitValue);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listEarningTypes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEarnigType(res.data.data);
        if (earnValue === "") {
          const earnn = res.data.data.filter((er) => {
            return er["earning_type"] == "price";
          });
          earnn.map((err) => {
            setEarn(err["earning_value"]);
          });
        } else {
          const earnn = res.data.data.filter((er) => {
            return er["earning_value"] == earnValue;
          });
          earnn.map((err) => {
            setEarn(err["earning_value"]);
          });
        }
      });
  }, [earnValue]);

  const multiSelectRef = useRef();
  const navigate = useNavigate();

  const handleRemoveSelectedItem = (value) => {
    multiSelectRef.current.onRemoveSelectedItem(value);
  };

  return (
    <div className={`${styles.addCampaignContent} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/products");
                }}
              />
              Create Campaign
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={{
              earnType: "",
              rewardType: "",
            }}
            onSubmit={(values) => {
              console.log(values);
              console.log(productIDs);
              setCampaign({
                itemsIds: productIDs,
                earnId: values.earnType,
                rewardId: values.rewardType,
              });
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col xs={12} sm={6}>
                      <Form.Label
                        className={`${styles.addInputLabel}`}
                        htmlFor="cityIDs_input"
                      >
                        Select Products
                      </Form.Label>
                      <Multiselect
                        className={`${styles.addMultiSelect}`}
                        ref={multiSelectRef}
                        customCloseIcon={<RiDeleteBin6Line className="ms-1" />}
                        options={products}
                        displayValue="customer_qrcode"
                        placeholder="Product Name"
                        name="products"
                        id="products"
                        onSelect={(selectedList, selectedItem) => {
                          console.log(selectedList);
                          let newArray = [];
                          let ids = [];
                          selectedList.map((it) => {
                            newArray.push(it);
                            ids.push(it["product_id"]);
                          });
                          setItems(newArray);
                          setProductsIDs(ids);
                        }}
                        onRemove={(selectedList, selectedItem) => {
                          let newArray = [];
                          let ids = [];
                          selectedList.map((it) => {
                            newArray.push(it);
                            ids.push(it["product_id"]);
                          });
                          setItems(newArray);
                          setProductsIDs(ids);
                        }}
                      />
                    </Col>
                    <Col xs={12}>
                      <div className="mt-3">
                        {items.map((item) => {
                          return (
                            <span
                              className={`${styles.chipCustom}`}
                              key={Math.random() * 100000}
                            >
                              <span className="mx-2">
                                {item["product_name"]}
                              </span>
                              <span className="mx-3">{item["price"]}</span>
                              <span className="mx-2">
                                {item["vendor_qrcode"]}
                              </span>
                              <i
                                className={`${styles.closeIcon} ms-1`}
                                onClick={() => {
                                  handleRemoveSelectedItem(item);
                                }}
                              >
                                <RiDeleteBin6Line />
                              </i>
                            </span>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Form.Group
                    as={Row}
                    className={`${styles.minReqContent} px-2 py-3 mb-4 d-flex justify-content-between align-items-center`}
                  >
                    <Form.Label
                      column
                      xs={12}
                      sm={3}
                      className={`${styles.addInputLabel}`}
                    >
                      Minimum Requirement
                    </Form.Label>
                    <Col xs={12} sm={3}>
                      <InputGroup className={`${styles.addInput}`} size="sm">
                        <InputGroup.Text className={`${styles.addLabel}`}>
                          <RiListSettingsFill />
                        </InputGroup.Text>
                        <Form.Select
                          size="sm"
                          name="earnType"
                          id="earnType"
                          className={`${styles.selectInput}`}
                          onChange={(e) => {
                            setEarnValue(e.currentTarget.value);
                            setFieldValue("earnType", e.currentTarget.value);
                          }}
                          value={values.earnType}
                        >
                          {earningType.map((earn) => {
                            return (
                              <option
                                value={earn["id"]}
                                key={Math.random() * 1000000}
                              >
                                {earn["earning_type"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col xs={12} sm={3}>
                      <InputGroup className={`${styles.addInput2}`} size="sm">
                        <Form.Control
                          type="text"
                          className={`${styles.miniReqInput}`}
                          name="miniRequire"
                          id="miniRequire"
                          onChange={handleChange}
                          value={earn}
                          disabled={true}
                        />
                        <InputGroup.Text className={`${styles.addLabel}`}>
                          EGP
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {rewardType.map((reward, index) => {
                      return (
                        <div key={Math.random() * 1000000}>
                          <input
                            hidden
                            id={`reward-${index}`}
                            type="radio"
                            value={reward["id"]}
                            name="rewardType"
                            className={`${styles.rewardCheck}`}
                            onChange={(e) => {
                              console.log(e);
                              setFieldValue("rewardType", e.target.value);
                              setShowReward(e.target.value);
                              handleSubmit();
                            }}
                            defaultChecked={values.rewardType == reward["id"]}
                          />
                          <label
                            htmlFor={`reward-${index}`}
                            className={`${styles.voucherBtn} btn btn-primary btn-md`}
                          >
                            {reward["reward_type"]}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Row>
        <Row>
          <Col xs={12}>
            {showReward == 1 ? (
              <Vouch vouchValue={discountValue} campaignData={campaign} />
            ) : showReward == 2 ? (
              <Freeunit unitValue={unitValue} />
            ) : (
              showReward == 3 && <Cashout cashValue={cashoutValue} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
