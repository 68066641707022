import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import "bootstrap/dist/css/bootstrap.rtl.css";
import i18next from "i18next";
import { ownerLogin } from "../../../redux/features/userSlice";
import Cookies from "js-cookie";
import styles from "./OwnerLogin.module.scss";
import { useTranslation } from "react-i18next";

export default function OwnerLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  }, []);

  const ownerTraderLoginValidation = yup.object().shape({
    phone: yup.string().required("رقم الهاتف مطلوب"),
    password: yup.string().required("كلمة السر مطلوبة"),
  });

  const [lang, setLang] = useState(Cookies.get("i18next"));

  const { t } = useTranslation();

  window.onload = () => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  };

  return (
    <div className={`${styles.OwnerLoginContent} py-5 px-2 p-md-5`}>
      {/* <div className={styles.langBtn}>
        {lang === "en" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("ar");
              Cookies.set("i18next", "ar");
              document.body.dir = "rtl";
              setLang("ar");
            }}
          >
            Arabic
          </Button>
        )}
        {lang === "ar" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("en");
              Cookies.set("i18next", "en");
              document.body.dir = "ltr";
              setLang("en");
            }}
          >
            انجليزية
          </Button>
        )}
      </div> */}
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <img
              src={require("../../../imgs/cpc-logo-new.png")}
              alt="cpc-logo"
              className={`${styles.cpcLogo}`}
            />
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <h5 className={`${styles.cpcRegTitle} mb-4`}>
              برنامج نقاط وياك للمحلات
            </h5>
            <div className={`${styles.loginFormContent} p-4 p-md-5`}>
              <Formik
                initialValues={{ phone: "", password: "" }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading("تحميل ...", {
                    transition: Zoom,
                    position: "bottom-right",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    ownerLogin({
                      phone: values.phone,
                      password: values.password,
                    })
                  ).then((res) => {
                    console.log(res);
                    if (res.payload.data.statusCode === 200) {
                      if (res.payload.data.roleName === "Owner") {
                        navigate(`/owner/dashboard/balance`);
                      }
                      if (res.payload.data.roleName === "Trader") {
                        navigate(`/trader/dashboard/home`);
                      }
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={ownerTraderLoginValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.loginLabel}`}>
                          {t("phoneNumber")}
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          maxLength={11}
                          placeholder=""
                          className={`${styles.loginInput}`}
                          id="phone"
                          name="phone"
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          value={values.phone}
                        />
                        {errors.phone && touched.phone && (
                          <Form.Text className="text-error">
                            {errors.phone}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.loginLabel}`}>
                          {t("password")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder=""
                          className={`${styles.loginInput}`}
                          id="password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                        {errors.password && touched.password && (
                          <Form.Text className="text-error">
                            {errors.password}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <NavLink
                        to="/cpc/ownerTrader/forget/password"
                        className={`${styles.forgetPassLink}`}
                      >
                        {t("forgetPass")}
                      </NavLink>
                      <div className="d-grid mt-4">
                        <Button
                          variant="primary"
                          type="submit"
                          className={`${styles.loginBtn} mb-3`}
                          disabled={isSubmitting}
                        >
                          {t("login")}
                        </Button>
                        <Button
                          variant="primary"
                          className={`${styles.loginBtn}`}
                          onClick={() => {
                            navigate("/cpc/register");
                          }}
                        >
                          {t("createAccount")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
