import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Outlet, NavLink, useParams, useNavigate } from "react-router-dom";
import { RiTeamLine } from "react-icons/ri";
import { HiOutlineGift } from "react-icons/hi";
import { BiLogOut } from "react-icons/bi";
import { MdLanguage } from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi";
import { FiMenu } from "react-icons/fi";
import { FaUserAlt } from "react-icons/fa";
import i18next from "i18next";
import styles from "./Sidebar.module.scss";
import { handleLogout } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

export default function Sidebar() {
  const [lang, setLang] = useState(Cookies.get("i18next"));

  const { t } = useTranslation();

  const roleName = useSelector((state) => state.cpc.user.login.roleName);

  useEffect(() => {
    if (lang === "ar") {
      import("bootstrap/dist/css/bootstrap.css");
      import("bootstrap/dist/css/bootstrap.rtl.css");
    }
  }, [lang]);

  const handleToggle = () => {
    var el = document.getElementById("wrapper");
    el.classList.toggle("toggled");
  };

  const handleCloseMenu = () => {
    var e2 = document.getElementById("wrapper");
    e2.classList.remove("toggled");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessName = useSelector(
    (state) => state.cpc.user.login.businessName
  );
  const goverNameAr = useSelector((state) => state.cpc.user.login.goverNameAr);
  const goverNameEn = useSelector((state) => state.cpc.user.login.goverNameEn);
  const cityNameAr = useSelector((state) => state.cpc.user.login.cityNameAr);
  const cityNameEn = useSelector((state) => state.cpc.user.login.cityNameEn);

  useEffect(() => {
    if (roleName === "Consumer") {
      import("./style.css");
    }
  }, [roleName]);

  const path = window.location.pathname;

  console.log(path);

  return (
    <div className={`${styles.customerSidebarContent}`}>
      <div className="d-flex" id="wrapper">
        <div id="sidebar-wrapper" className="sidebar-wrapper-customer">
          <div className="sidebar-heading text-center py-4 primary-text fw-bold border-bottom  d-flex justify-content-center align-items-center flex-column">
            <h6>{t("userDash")}</h6>
            <div className={`${styles.traderProfile}`}>
              <FaUserAlt />
            </div>
            <p className={`${styles.businessName} mt-2 mb-1`}>{businessName}</p>
            <span className={`${styles.location}`}>
              {i18next.language == "ar" ? (
                <h5>{`${goverNameAr}, ${cityNameAr}`}</h5>
              ) : (
                <h5>{`${goverNameEn}, ${cityNameEn}`}</h5>
              )}
            </span>
            <Button
              size="sm"
              variant="primary"
              className={`${styles.changePasswordBtn} mt-2`}
              onClick={() => {
                handleCloseMenu();
                navigate("/customer/dashboard/change/password");
              }}
            >
              {t("changePassword")}
            </Button>
          </div>
          <div className="list-group list-group-flush my-3">
            <NavLink
              to="/customer/dashboard/home"
              className={`${styles.listItem} list-group-item mb-3`}
              onClick={() => {
                handleCloseMenu();
              }}
            >
              <HiOutlineHome className={`${styles.listIcon} mx-2`} />
              {t("home")}
            </NavLink>
            <NavLink
              to="/customer/dashboard/gifts"
              className={`${styles.listItem} list-group-item mb-3`}
              onClick={() => {
                handleCloseMenu();
              }}
            >
              <HiOutlineGift className={`${styles.listIcon} mx-2`} />
              {t("giftss")}
            </NavLink>
            {lang === "ar" ? (
              <Button
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  i18next.changeLanguage("en");
                  document.body.dir = "ltr";
                  setLang("en");
                  handleCloseMenu();
                }}
              >
                <MdLanguage className={`${styles.listIcon} mx-2`} />
                English
              </Button>
            ) : (
              lang === "en" && (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("ar");
                    document.body.dir = "rtl";
                    setLang("ar");
                    handleCloseMenu();
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} mx-2`} />
                  العربية
                </Button>
              )
            )}
            <Button
              variant="danger"
              className={`${styles.logoutBtn} mt-5 py-3 px-4`}
              onClick={() => {
                dispatch(handleLogout());
                navigate("/cpc/customer/login");
              }}
            >
              <BiLogOut className="mx-2 fs-5" />
              {t("logout")}
            </Button>
          </div>
        </div>

        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-3 px-4">
            <div className="d-flex align-items-center">
              <FiMenu
                id="menu-toggle"
                className={`${styles.menuIcon} me-3`}
                onClick={() => {
                  handleToggle();
                }}
              />
            </div>
          </nav>
          <div
            className={`${
              path !== "/customer/dashboard/gifts" && styles.adminContent
            }  p-3`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
