import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { Formik } from "formik";
import styles from "./Vouch.module.scss";
import { adminCreateCampaign } from "../../../../../../redux/features/adminSlice";
import { useDispatch, useSelector } from "react-redux";

export default function Vouch(props) {
  console.log(props);

  const [value, setValue] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    props.vouchValue.map((val) => {
      setValue(val["reward_value"]);
    });
    setData(props.campaignData);
  }, []);

  console.log(data);

  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);

  return (
    <div className={`${styles.vouchContent} py-4`}>
      <Container>
        <Row>
          <Col xs={12}>
            <Formik
              initialValues={{
                sDate: "",
                eDate: "",
                campaignName: "",
              }}
              onSubmit={(values) => {
                console.log(values);
                // dispatch(
                //   adminCreateCampaign({
                //     campaignName: values.campaignName,
                //     startDate: values.sDate,
                //     endDate: values.eDate,
                //     productIDs: data.itemsIds,
                //     earningID: data.earnId,
                //     rewardID: data.rewardId,
                //     token,
                //   })
                // ).then((res) => {
                //   console.log(res);
                // });
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      as={Row}
                      className={`${styles.formGroupCashout} mb-3 px-2 py-4`}
                    >
                      <Col xs={1}>
                        <Form.Label className={`${styles.formLabel}`}>
                          Value
                        </Form.Label>
                      </Col>
                      <Col xs={4}>
                        <InputGroup
                          size="sm"
                          className={`${styles.inputGroupForm}`}
                        >
                          <Form.Control
                            type="text"
                            className={`${styles.vouchInput}`}
                            name="voucherValue"
                            id="voucherValue"
                            onChange={handleChange}
                            disabled={true}
                            value={`${value * 100} %`}
                          />
                          <InputGroup.Text>EGP</InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <Form.Text className={`${styles.formGroupCashoutNote}`}>
                          This amount will be transferred to the merchant's
                          e-wallet after deduction of required fees.
                        </Form.Text>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.formGroupCashout} mb-3 px-2 py-4`}
                    >
                      <h4 className="mb-4">Campaign Date:</h4>
                      <Col xs={1}>
                        <Form.Label className={`${styles.formLabel}`}>
                          From
                        </Form.Label>
                      </Col>
                      <Col xs={4}>
                        <Form.Control
                          type="date"
                          name="sDate"
                          id="sDate"
                          onChange={handleChange}
                          value={values.sDate}
                        />
                      </Col>
                      <Col xs={1}>
                        <Form.Label className={`${styles.formLabel}`}>
                          To
                        </Form.Label>
                      </Col>
                      <Col xs={4}>
                        <Form.Control
                          type="date"
                          name="eDate"
                          id="eDate"
                          onChange={handleChange}
                          value={values.eDate}
                        />
                      </Col>
                      <Col xs={12} sm={6} className="mt-4">
                        <Form.Control
                          type="text"
                          placeholder="Enter Campaign Name"
                          name="campaignName"
                          id="campaignName"
                          onChange={handleChange}
                          value={values.campaignName}
                        />
                      </Col>
                    </Form.Group>
                    <Row>
                      <Col xs={12} className="text-center">
                        <Button
                          type="submit"
                          variant="primary"
                          className={`${styles.saveBtn}`}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
