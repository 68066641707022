import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../../pagination/Pagination";
import { handleLogout } from "../../../../redux/features/userSlice";
import { createPDFQrCode } from "../../../../redux/features/stationSlice";
import fileDownload from "js-file-download";
import styles from "./StationOperators.module.scss";

export default function StationOperators() {
  const [operators, setOperators] = useState([]);
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [total, setTotal] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = useSelector((state) => state.cpc.user.login.token);

  const { stationID } = useParams();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/Operators", {
        params: {
          sort: "asc",
          station_id: stationID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          const filterOperators = res.data.data.filter(
            (ope) => ope["station_id"] == stationID
          );
          console.log(filterOperators);
          filterOperators.map((filOpe) => {
            setOperators(filOpe["stationOperators"]);
            setTotal(filOpe["stationOperators"].length);
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + postsPerPage;
  const currentPosts = operators.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * postsPerPage) % operators.length;
    setItemOffset(newOffset);
    Cookies.set("page", event.selected);
  };

  useEffect(() => {
    if (Cookies.get("page")) {
      const newOffsett =
        (Number(Cookies.get("page")) * postsPerPage) % operators.length;
      setItemOffset(newOffsett);
    } else {
      setItemOffset(0);
    }
  });

  return (
    <div className={`${styles.stationOperatorsContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}></h3>
          </Col>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations");
                }}
              />
              {t("operatingDetailss")}
            </h3>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
              <div className={`${styles.filterForm} mb-3 mb-lg-0`}>
                {/* <Formik
                  initialValues={{ governID: "" }}
                  onSubmit={(values) => {
                    setGovernId(values.governID);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.filterInput}`}>
                          <InputGroup.Text className={`${styles.filterLabel}`}>
                            <HiFilter />
                          </InputGroup.Text>
                          <Form.Select
                            size="sm"
                            name="governID"
                            id="governID"
                            onChange={(e) => {
                              setFieldValue("governID", e.currentTarget.value);
                              handleSubmit();
                            }}
                            className={`${styles.filterSelect}`}
                            value={values.governID}
                          >
                            <option value="">{t("gover")}</option>
                            {governorates.map((govern, index) => {
                              return (
                                <option key={index} value={govern.GovernateID}>
                                  {i18next.language == "ar"
                                    ? govern["governorate_name_ar"]
                                    : govern["governorate_name_en"]}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik> */}
              </div>
              <div className={`${styles.searchForm}`}>
                {/* <Formik
                  initialValues={{ searchValue: "" }}
                  onSubmit={(values) => {}}
                >
                  {({ values, touched, handleChange, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.searchInput}`}>
                          <InputGroup.Text className={`${styles.searchLabel}`}>
                            <BiSearch />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("search")}
                            className={`${styles.searchField}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => {
                              if (page !== 1) {
                                setPage(1);
                              }
                              setSearchValue(e.currentTarget.value);
                            }}
                          />
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {currentPosts.length > 0 ? (
            currentPosts.map((operator, index) => {
              console.log(operator);
              return (
                <Col xs={12} key={index}>
                  <div className={`${styles.bgGrey} px-4 mb-3`}>
                    <div
                      className={`${styles.activatorInfoContent} d-flex align-items-center`}
                    >
                      <div className={`${styles.activatorInfo}`}>
                        <h5>{operator["phone_number"]}</h5>
                        {/* <p dir="rtl">{operator["name"]}</p> */}
                        {/* <span className="mt-3">01121830506</span> */}
                      </div>
                    </div>
                    <div
                      className={`${styles.activatorBtnContent}  h-100 d-flex justify-content-left justify-content-md-center align-items-center flex-wrap mt-2`}
                    >
                      <Button
                        variant="primary"
                        className={`${styles.detailsBtn} me-2`}
                        onClick={() => {
                          dispatch(
                            createPDFQrCode({
                              operatorID: operator["operator_id"],
                              token,
                            })
                          ).then((res) => {
                            console.log(res);
                            // fileDownload(
                            //   res.payload.data,
                            //   `operator-${operator["name"]}_qrcode.pdf`
                            // );
                          });
                        }}
                      >
                        Download QR
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            })
          ) : (
            <Col xs={12}>
              <div className={`${styles.bgGrey} px-4 mb-3`}>
                <h5 className="w-100 text-center">No Active Operators</h5>
              </div>
            </Col>
          )}
          <Col xs={12}>
            <Pagination
              totalPosts={total}
              postsPerPage={postsPerPage}
              onChangePage={handlePageClick}
              currentPage={itemOffset}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
