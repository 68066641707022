import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import styles from "./AdminLogin.module.scss";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { userLogin } from "../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import i18next from "i18next";

export default function AdminLogin() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(Cookies.get("i18next"));
  const adminLoginValidation = yup.object().shape({
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { merchantID } = useParams();

  useEffect(() => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  }, []);

  return (
    <div className={`${styles.adminLogin} py-5 px-2 p-md-5`}>
      <Container fluid>
        <Row>
          <Col xs={12} className="mb-4">
            <img
              src={require("../../../imgs/cpc-logo-new.png")}
              alt="cpc-logo"
              className={`${styles.cpcLogo}`}
            />
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className={`${styles.loginFormContent} p-4 p-md-5`}>
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading("Loading...", {
                    transition: Zoom,
                    position: "bottom-left",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    userLogin({
                      email: values.email,
                      password: values.password,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      if (res.payload.data.roleName === "Admin") {
                        navigate(`/admin/dashboard/team`);
                      }
                      if (res.payload.data.roleName === "Activator") {
                        navigate(`/activator/dashboard/shops`);
                      }
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={adminLoginValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.loginLabel}`}>
                          {t("email")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("enterEmail")}
                          className={`${styles.loginInput}`}
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <Form.Text className="text-error">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.loginLabel}`}>
                          {t("password")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("enterPass")}
                          className={`${styles.loginInput}`}
                          id="password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />

                        {errors.password && touched.password && (
                          <Form.Text className="text-error">
                            {errors.password}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <NavLink
                        to="/cpc/forget/password"
                        className={`${styles.forgetPassLink}`}
                      >
                        {t("forgetPass")}
                      </NavLink>
                      <div className="d-grid mt-4">
                        <Button
                          variant="primary"
                          type="submit"
                          className={`${styles.loginBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("login")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
