import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Formik, move } from "formik";
import { MdError } from "react-icons/md";
import * as yup from "yup";
import i18next from "i18next";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import styles from "./CustomerBankCard.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CustomerCashTerms from "../wallet/terms/CustomerCashTerms";
import {
  bankCashout,
  getPointsValue,
} from "../../../../../redux/features/customerSlice";
import failedMessage from "../../../../../messages/Failed";
import CustomerMessage from "../../../message/CustomerMessage";
import { mezaPrepaidCashout } from "../../../../../redux/features/customerSlice";
import { useDispatch, useSelector } from "react-redux";

export default function CustomerBankCard() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [finalValue, setFinalValue] = useState("00");
  const [banks, setBanks] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const [finalMessage, setFinalMessage] = useState("");

  const cashoutValidation = yup.object().shape({
    cashAmount: yup.number().required("المبلغ المراد مطلوب"),
    bankCode: yup.string().required("اسم البنك مطلوب"),
    fullName: yup.string().required("الاسم بالكامل مطلوب"),
    password: yup.string().required("تاكيد كلمة السر مطلوب"),
    cardType: yup.string().required("ادخل نوع الكارت"),
  });

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/banks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let result = res.data.data.banks.sort((a, b) =>
          a["bank_name"].localeCompare(b["bank_name"])
        );
        setBanks(result);
      });
  }, []);

  function clickEvent(first, last) {
    var firstText = document.getElementById(first).value;
    if (firstText.length == 4) {
      document.getElementById(last).disabled = false;
      document.getElementById(last).focus();
    }
  }

  const [termsMessageLine, setTermsMessageLine] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <div className={`${styles.customerBankCardContent} py-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <Formik
              initialValues={{
                cashAmount: "",
                points: "",
                bankCode: "",
                cardNumber: [],
                fullName: "",
                password: "",
                cardType: "",
              }}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 3000);
                let cardNumberFinal = values.cardNumber.join("-");
                console.log(values, cardNumberFinal);
                if (values.cardType === "prepaid") {
                  dispatch(
                    mezaPrepaidCashout({
                      amount: values.cashAmount,
                      points: values.points,
                      fullName: values.fullName,
                      cardNumber: cardNumberFinal,
                      bankCode: values.bankCode,
                      password: values.password,
                      token,
                    })
                  ).then((res) => {
                    console.log(res);
                    if (res.payload.data.statusCode === 200) {
                      setShowMsg(true);
                      if (i18next.language == "ar") {
                        setFinalMessage(res.payload.data.messageAr);
                      } else {
                        setFinalMessage(res.payload.data.message);
                      }
                    } else {
                      const loadReq = toast.loading(t("loading"), {
                        transition: Zoom,
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                      });
                      failedMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    }
                  });
                } else {
                  dispatch(
                    bankCashout({
                      amount: values.cashAmount,
                      points: values.points,
                      fullName: values.fullName,
                      cardNumber: cardNumberFinal,
                      bankCode: values.bankCode,
                      password: values.password,
                      token,
                    })
                  ).then((res) => {
                    console.log(res);
                    if (res.payload.data.statusCode === 200) {
                      setShowMsg(true);
                      if (i18next.language == "ar") {
                        setFinalMessage(res.payload.data.messageAr);
                      } else {
                        setFinalMessage(res.payload.data.message);
                      }
                    } else {
                      const loadReq = toast.loading(t("loading"), {
                        transition: Zoom,
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                      });
                      failedMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    }
                  });
                }
              }}
              validationSchema={cashoutValidation}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <Form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => checkKeyDown(e)}
                  >
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("enterAmount")}
                        </Form.Label>
                        <NavLink
                          onClick={() => {
                            setTermsMessageLine(true);
                          }}
                          className={`${styles.termsBtn}`}
                        >
                          <Form.Text className="d-block">
                            *{t("sendGiftNote")}
                          </Form.Text>
                        </NavLink>
                      </Col>
                      <Col xs={12} lg={2}>
                        <Form.Control
                          type="number"
                          className={`${styles.cashoutInput} mb-3`}
                          size="sm"
                          id="cashAmount"
                          name="cashAmount"
                          onChange={(e) => {
                            setFieldValue("cashAmount", e.currentTarget.value);
                            dispatch(
                              getPointsValue({
                                token,
                                amount: Number(e.currentTarget.value),
                                cashType: "bank",
                              })
                            ).then((res) => {
                              setAlertMessage(null);
                              if (res.payload.data.statusCode === 200) {
                                setFieldValue(
                                  "points",
                                  res.payload.data.pointsNeeded
                                );
                                setFinalValue(res.payload.data.pointsNeeded);
                              } else {
                                setAlertMessage(
                                  <span
                                    className={`${styles.alertMessageContent} px-3 py-2`}
                                  >
                                    {i18n.language === "ar"
                                      ? res.payload.data.errorAr
                                      : res.payload.data.error}
                                  </span>
                                );
                                setFinalValue("00");
                              }
                            });
                          }}
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          value={values.cashAmount}
                        />
                        {errors.cashAmount && touched.cashAmount && (
                          <Form.Text className="text-error">
                            {errors.cashAmount}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} lg={4}>
                        <Form.Text>
                          <p className={`${styles.totalValuepara}`}>
                            {t("totalPoints")}
                            <span
                              className={`${styles.finalValueStyle} m-3 px-3 py-2`}
                            >
                              {finalValue}
                            </span>
                            {t("point")}
                          </p>
                        </Form.Text>
                      </Col>
                      <Col xs={12} className="mt-2">
                        {alertMessage !== null && alertMessage}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} className="mb-2">
                        <Form.Label className="d-block">
                          {t("chooseCard")}
                        </Form.Label>
                      </Col>
                      <Col xs={12}>
                        <Form.Check
                          reverse
                          type="radio"
                          label={t("firstCardCredit")}
                          name="cardType"
                          id={`cardType-${1}`}
                          onChange={(e) => {
                            if (e.currentTarget.value === true) {
                              setFieldValue("cardType", "cash");
                            }
                          }}
                        />
                        <Form.Check
                          reverse
                          type="radio"
                          label={t("secondCardCredit")}
                          name="cardType"
                          id={`cardType-${2}`}
                          onChange={(e) => {
                            if (e.currentTarget.value === true) {
                              setFieldValue("cardType", "prepaid");
                            }
                          }}
                        />
                      </Col>
                      {errors.cardType && touched.cardType && (
                        <Form.Text className="text-error">
                          {errors.cardType}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} className="mb-2">
                        <Form.Label className="d-block">
                          {t("cardNumber")}
                        </Form.Label>
                      </Col>
                      <Col xs={12}>
                        <div
                          className="d-flex justify-content-center align-items-center flex-grow-1"
                          dir="ltr"
                        >
                          <Form.Control
                            type="text"
                            className={`${styles.cashoutInput} ${styles.cardNumberInput} mx-1 mx-md-3 mx-lg-3`}
                            size="sm"
                            placeholder={t("startHere")}
                            id={`cardNumber-${0}`}
                            name="cardNumber"
                            maxLength={4}
                            onKeyUp={() => {
                              clickEvent("cardNumber-0", "cardNumber-1");
                            }}
                            onChange={(e) => {
                              setFieldValue(`cardNumber[${0}]`, e.target.value);
                            }}
                          />
                          <Form.Control
                            type="text"
                            className={`${styles.cashoutInput} ${styles.cardNumberInput} mx-1 mx-md-3 mx-lg-3`}
                            size="sm"
                            id={`cardNumber-${1}`}
                            name="cardNumber"
                            maxLength={4}
                            onKeyUp={() => {
                              clickEvent("cardNumber-1", "cardNumber-2");
                            }}
                            onChange={(e) => {
                              setFieldValue(`cardNumber[${1}]`, e.target.value);
                            }}
                            disabled={true}
                          />
                          <Form.Control
                            type="text"
                            className={`${styles.cashoutInput} ${styles.cardNumberInput} mx-1 mx-md-3 mx-lg-3`}
                            size="sm"
                            id={`cardNumber-${2}`}
                            name="cardNumber"
                            maxLength={4}
                            onKeyUp={() => {
                              clickEvent("cardNumber-2", "cardNumber-3");
                            }}
                            onChange={(e) => {
                              setFieldValue(`cardNumber[${2}]`, e.target.value);
                            }}
                            disabled={true}
                          />
                          <Form.Control
                            type="text"
                            className={`${styles.cashoutInput} ${styles.cardNumberInput} mx-1 mx-md-3 mx-lg-3`}
                            size="sm"
                            id={`cardNumber-${3}`}
                            name="cardNumber"
                            maxLength={4}
                            onChange={(e) => {
                              setFieldValue(`cardNumber[${3}]`, e.target.value);
                            }}
                            disabled={true}
                          />
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("bankName")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Select
                          className={`${styles.cashoutSelect}`}
                          name="bankCode"
                          id="bankCode"
                          onChange={(e) => {
                            setFieldValue("bankCode", e.currentTarget.value);
                          }}
                        >
                          <option value="">{t("chooseBank")}</option>
                          {banks.map((bank, index) => {
                            return (
                              <option value={bank["bank_code"]} key={index}>
                                {bank["bank_name"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      {errors.bankCode && touched.bankCode && (
                        <Form.Text className="text-error">
                          {errors.bankCode}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("cardHolderName")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="fullName"
                          name="fullName"
                          onChange={handleChange}
                        />
                      </Col>
                      {errors.fullName && touched.fullName && (
                        <Form.Text className="text-error">
                          {errors.fullName}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("confirmPass")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Control
                          type="password"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      </Col>
                      {errors.password && touched.password && (
                        <Form.Text className="text-error">
                          {errors.password}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <div className="d-grid">
                      <Button
                        type="submit"
                        variant="danger"
                        size="md"
                        className={`${styles.saveBtn}`}
                        disabled={isSubmitting}
                      >
                        {t("sendCash")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
      <CustomerMessage
        show={showMsg}
        onHide={() => {
          setShowMsg(false);
        }}
        message={finalMessage}
      />
      <CustomerCashTerms
        show={termsMessageLine}
        onHide={() => {
          setTermsMessageLine(false);
        }}
      />
    </div>
  );
}
