import React from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./CustomerInfo.module.scss";
import { useTranslation } from "react-i18next";

export default function CustomerInfo(props) {
  console.log(props.customerinfo);

  const customer = props.customerinfo;
  const { t } = useTranslation();

  if (props.customerinfo != "") {
    return (
      <div className={`${styles.customerInfoContent}`}>
        <Modal {...props} size="sm" centered>
          <Modal.Body className={`${styles.giftPointBody}`}>
            <p className="my-1">{`${customer["firstName"]} ${customer["lastName"]}`}</p>
            <p className="my-1">{customer["email"]}</p>
            <p className="my-1">{customer["phone"]}</p>
            <p className="my-1">{customer["pointsBalance"]} Points</p>
            <div className="d-grid mt-3">
              <Button
                size="sm"
                variant="priamry"
                className={`${styles.okBtn}`}
                onClick={() => {
                  props.onHide();
                }}
              >
                {t("ok")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
