import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styles from "./StationReport.module.scss";

export default function StationReport() {
  const navigate = useNavigate();
  const girdRef = useRef();
  const [gridApi, setGridApi] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);
  const userID = useSelector((state) => state.cpc.user.login.userID);

  const { t } = useTranslation();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Order ID",
      field: "fuelPayments",
      colId: "fuelPayment",
      minWidth: 500,
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            <ul>
              {result.data["fuelPayments"].map((pay, index) => {
                return <li key={index}>{pay["order_id"]}</li>;
              })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: "Phone Number",
      field: "phone_number",
      cellRenderer: (result) => {
        let fuelPaymentsLength = result.data.fuelPayments.length;
        return (
          <div className={`${styles.orderIdContent}`}>
            <ul>
              {[...Array(fuelPaymentsLength)].map((x, i) => (
                <li key={i}>{result.data["phone_number"]}</li>
              ))}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: "Operator Name",
      field: "name",
      cellRenderer: (result) => {
        let fuelPaymentsLength = result.data.fuelPayments.length;
        return (
          <div className={`${styles.orderIdContent}`}>
            <ul>
              {[...Array(fuelPaymentsLength)].map((x, i) => (
                <li key={i}>{result.data["name"]}</li>
              ))}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: "Amount",
      field: "Amount",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            <ul>
              {result.data["fuelPayments"].map((pay, index) => {
                return <li key={index}>{pay["amount"]}</li>;
              })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: "Points",
      field: "pointsUsed",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            <ul>
              {result.data["fuelPayments"].map((pay, index) => {
                return <li key={index}>{pay["points_used"]}</li>;
              })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: "Date & Time",
      field: "",
      minWidth: 300,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            <ul>
              {result.data["fuelPayments"].map((pay, index) => {
                return (
                  <li key={index}>
                    {moment(pay["createdAt"]).format("MM-DD-YYYY, h:mm:ss a")}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      },
    },
  ]);

  const defaultColDef = {
    flex: 1,
    sortable: false,
    filter: false,
    floatingFilter: false,
    minWidth: 200,
  };

  const [rowData, setRowData] = useState([]);
  const [filter, setFilter] = useState({
    sort: "asc",
    sortBy: "createdAt",
    startDate: "",
    endDate: "",
    businessName: "",
    user_id: "",
  });

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://evouchers.shareecoin.com/CPC/fuelStation/operators/transactions",
  //       {
  //         params: {
  //           startDate: "",
  //           endDate: "",
  //           page: "",
  //           limit: "",
  //           sortBy: "name",
  //           order: "desc",
  //           minAmount: "",
  //           maxAmount: "",
  //           name: "",
  //         },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data.data);
  //       const targetStation = res.data.data.filter(
  //         (target) => target["stationOwner_id"] == userID
  //       );
  //       targetStation.map((station) => {
  //         setRowData(station["stationOperators"]);
  //       });
  //     });
  // }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .get(
        "https://evouchers.shareecoin.com/CPC/fuelStation/operators/transactions",
        {
          params: {
            startDate: "",
            endDate: "",
            page: "",
            limit: "",
            sortBy: "name",
            order: "desc",
            minAmount: "",
            maxAmount: "",
            name: "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        const targetStation = res.data.data.filter(
          (target) => target["stationOwner_id"] == userID
        );
        targetStation.map((station) => {
          params.api.applyTransaction({ add: station["stationOperators"] });
        });
      });
  };

  const handleExport = () => {
    gridApi.exportDataAsCsv({
      fileName: "Stations-Report",
      processCellCallback: function (params) {
        console.log(params);
        if (params.column.getColId() === "fuelPayment") {
          params.value.map((val) => {
            console.log(val["order_id"]);
            return val["order_id"].toString();
          });
        }
        // if (params.column.getColId() === "city") {
        //   return params.value[0]["city"]["city_name_en"];
        // }
        return params.value;
      },
    });
  };

  console.log(rowData);

  return (
    <div className={`${styles.stationReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/stationOwner/dashboard/home");
                }}
              />
              Station Report
            </h3>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine ag-theme-quartz"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // rowData={rowData}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3 text-center">
            <Button
              variant="primary"
              className={`${styles.downloadBtn}`}
              onClick={() => {
                handleExport();
              }}
            >
              {t("downloadReport")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
