import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addShop,
  deleteBranch,
} from "../../../../redux/features/activatorSlice";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import styles from "./AddShops.module.scss";
import "./style.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function AddShops() {
  const [cities, setCities] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [governID, setGovernID] = useState(null);
  const [activeCity, setAcitveCity] = useState(false);
  const [owner, setOwner] = useState([]);

  const [branchCounter, setBranchCounter] = useState(1);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGoverns(res.data["Governates List"]);
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: governID,
        Function: "ListCites",
      })
      .then((res) => {
        setCities(res.data["List of Cities"]);
      });
  }, [governID]);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/reports/listOwners", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const filterOwner = res.data.owners.filter((owner) => {
          return owner.userID == ownerID;
        });
        setOwner(filterOwner);
      });
  }, []);

  function makeRandomEmail(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.cpc.user.login.token);

  const ownerID = useParams().ownerID;

  const [shops, setShops] = useState([]);

  useEffect(() => {
    axios
      .get(`https://evouchers.shareecoin.com/CPC/list-Owner-Traders/${ownerID}`)
      .then((res) => {
        setShops(res.data.ownersAndTraders[0]["traders_data"]);
      });
  }, []);


  const { t } = useTranslation();

  return (
    <div className={`${styles.addShopsActivator}`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>{t("addBranch")}</h3>
          </Col>
        </Row>
        {owner.map((own) => {
          return (
            <Row
              className={`${styles.ownerData} mt-3`}
              key={Math.random() * 1000000}
            >
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} xs={12} sm={6} className="mb-3">
                    <Form.Label className={`${styles.addInputLabel}`}>
                      {t("ownerName")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${styles.addInput}`}
                      value={`${own.firstName} ${own.lastName}`}
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} sm={6}>
                    <Form.Label className={`${styles.addInputLabel}`}>
                      {t("phoneNumber")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${styles.addInput}`}
                      value={`${own.phone}`}
                      disabled={true}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} xs={12} sm={6} className="mb-3">
                    <Form.Label className={`${styles.addInputLabel}`}>
                      {t("email")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${styles.addInput}`}
                      value={`${own.email}`}
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} sm={6}>
                    <Form.Label className={`${styles.addInputLabel}`}>
                      {t("businessName")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${styles.addInput}`}
                      value={`${own.businessName}`}
                      disabled={true}
                    />
                  </Form.Group>
                </Row>
              </Form>
            </Row>
          );
        })}

        <Accordion defaultActiveKey={0} className="mt-4">
          {shops.map((shop, index) => {
            return (
              <Accordion.Item eventKey={index} className="mb-3" key={index}>
                <Accordion.Header className={`${styles.branchHeader}`}>
                  <div>
                    <span>{shop["trader_branchName"]}</span>
                    <span className={`${styles.ownerInfo} mt-2`}>{`${t(
                      "traderName"
                    )}: ${shop["trader_firstName"]} ${
                      shop["trader_lastName"]
                    }`}</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className={`${styles.branchBody}`}>
                  <div className="w-100">
                    <Button
                      variant="danger"
                      size="sm"
                      className={`${styles.deleteBranchBtn} mb-3`}
                      onClick={() => {
                        dispatch(
                          deleteBranch({
                            branchID: shop["trader_id"],
                            token,
                          })
                        ).then((res) => {
                          const loadReq = toast.loading(t("loading"), {
                            transition: Zoom,
                            position:
                              i18next.language == "ar"
                                ? "bottom-right"
                                : "bottom-left",
                          });
                          if (res.payload.data.statusCode === 200) {
                            successMessage(
                              res.payload.data.message,
                              res.payload.data.messageAr,
                              loadReq
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 3000);
                          } else {
                            failedMessage(
                              res.payload.data.error,
                              res.payload.data.errorAr,
                              loadReq
                            );
                          }
                        });
                      }}
                    >
                      {t("deleteBranch")}
                    </Button>
                  </div>

                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} xs={12} sm={6}>
                        <Form.Label className={`${styles.addInputLabel}`}>
                          {t("phoneNumber")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${styles.addInput}`}
                          value={`${shop["trader_phone"]}`}
                          disabled={true}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={12} sm={6}>
                        <Form.Label className={`${styles.addInputLabel}`}>
                          {t("address")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${styles.addInput}`}
                          value={`${shop["trader_address"]}`}
                          disabled={true}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} xs={12} sm={6} className="mb-3">
                        <Form.Label className={`${styles.addInputLabel}`}>
                          {t("gover")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${styles.addInput}`}
                          value={`${shop["trader_governorate_name_en"]}`}
                          disabled={true}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={12} sm={6}>
                        <Form.Label className={`${styles.addInputLabel}`}>
                          {t("city")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${styles.addInput}`}
                          value={`${shop["trader_city_name_en"]}`}
                          disabled={true}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>

        <Accordion defaultActiveKey={0} className="mt-4">
          <Formik
            initialValues={{
              shopInfo: [
                {
                  branchName: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  goveID: "",
                  cityId: "",
                  address: "",
                  addressAr: ``,
                  phone: "",
                  NID: "",
                },
              ],
            }}
            onSubmit={(values) => {
              const loadReq = toast.loading(t("loading"), {
                transition: Zoom,
                position:
                  i18next.language == "ar" ? "bottom-right" : "bottom-left",
              });
              dispatch(
                addShop({
                  token,
                  ownerID,
                  shopInfo: values.shopInfo,
                })
              ).then((res) => {
                if (res.payload.data.statusCode === 200) {
                  successMessage(
                    res.payload.data.message,
                    res.payload.data.messageAr,
                    loadReq
                  );
                  navigate("/activator/dashboard/shops");
                } else {
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form id="addShopForm" onSubmit={handleSubmit}>
                  {[...Array(branchCounter)].map((e, i) => {
                    return (
                      <Accordion.Item eventKey={i} className="mb-3" key={i}>
                        <Accordion.Header className={`${styles.branchHeader}`}>
                          Branch {i + 1}
                        </Accordion.Header>
                        <Accordion.Body className={`${styles.branchBody}`}>
                          <Row className="mb-3">
                            <Form.Group
                              as={Col}
                              xs={12}
                              sm={6}
                              className="mb-3"
                            >
                              <Form.Label className={`${styles.addInputLabel}`}>
                                {t("firstName")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("firstName")}
                                className={`${styles.addInput}`}
                                name={`shopInfo[${i}].firstName`}
                                id={`shopInfo[${i}].firstName`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shopInfo[${i}].firstName`,
                                    e.currentTarget.value
                                  );
                                  setFieldValue(
                                    `shopInfo[${i}].NID`,
                                    Date.now().toString()
                                  );
                                }}
                              />
                              <Form.Text className="text-error">{}</Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} sm={6}>
                              <Form.Label className={`${styles.addInputLabel}`}>
                                {t("lastName")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("lastName")}
                                className={`${styles.addInput}`}
                                name={`shopInfo[${i}].lastName`}
                                id={`shopInfo[${i}].lastName`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shopInfo[${i}].lastName`,
                                    e.currentTarget.value
                                  );
                                  setFieldValue(
                                    `shopInfo[${i}].email`,
                                    makeRandomEmail(30) + "@gmail.com"
                                  );
                                }}
                              />
                            </Form.Group>
                          </Row>
                          <Row className="mb-3">
                            <Form.Group
                              as={Col}
                              xs={12}
                              sm={6}
                              className="mb-3"
                            >
                              <Form.Label className={`${styles.addInputLabel}`}>
                                {t("phoneNumber")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                maxLength={11}
                                placeholder={t("phoneNumber")}
                                className={`${styles.addInput}`}
                                name={`shopInfo[${i}].phone`}
                                id={`shopInfo[${i}].phone`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shopInfo[${i}].phone`,
                                    e.currentTarget.value
                                  );
                                }}
                                onKeyPress={(e) => {
                                  var char = String.fromCharCode(e.which);
                                  if (!/[0-9]/.test(char)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              xs={12}
                              sm={6}
                              className="mb-3"
                            >
                              <Form.Label className={`${styles.addInputLabel}`}>
                                {t("branchName")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("branchName")}
                                className={`${styles.addInput}`}
                                name={`shopInfo[${i}].branchName`}
                                id={`shopInfo[${i}].branchName`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shopInfo[${i}].branchName`,
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            </Form.Group>
                          </Row>
                          <Row className="mb-3">
                            <Form.Group
                              as={Col}
                              xs={12}
                              sm={6}
                              className="mb-3"
                            >
                              <Form.Label className={`${styles.addInputLabel}`}>
                                {t("gover")}
                              </Form.Label>
                              <Form.Select
                                className={`${styles.addSelect}`}
                                name={`shopInfo[${i}].goveID`}
                                id={`shopInfo[${i}].goveID`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shopInfo[${i}].goveID`,
                                    e.currentTarget.value
                                  );
                                  setGovernID(e.currentTarget.value);
                                  setAcitveCity(true);
                                }}
                              >
                                <option value="">{t("selectGover")}</option>
                                {governs.map((gov) => {
                                  return (
                                    <option
                                      key={gov.GovernateID}
                                      value={gov.GovernateID}
                                    >
                                      {gov["governorate_name_en"]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} sm={6}>
                              <Form.Label className={`${styles.addInputLabel}`}>
                                {t("districts")}
                              </Form.Label>
                              <Form.Select
                                className={`${styles.addSelect}`}
                                name={`shopInfo[${i}].cityId`}
                                id={`shopInfo[${i}].cityId`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shopInfo[${i}].cityId`,
                                    e.currentTarget.value
                                  );
                                }}
                                disabled={!activeCity}
                              >
                                <option value="">{t("selectDistrict")}</option>
                                {cities.map((city) => {
                                  return (
                                    <option
                                      key={city.cityID}
                                      value={city.cityID}
                                    >
                                      {city.city_name_en}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3"></Row>
                          <Row className="mb-3">
                            <Form.Group
                              as={Col}
                              xs={12}
                              sm={12}
                              className="mb-3"
                            >
                              <Form.Label className={`${styles.addInputLabel}`}>
                                {t("address")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("address")}
                                className={`${styles.addInput}`}
                                name={`shopInfo[${i}].address`}
                                id={`shopInfo[${i}].address`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shopInfo[${i}].address`,
                                    e.currentTarget.value
                                  );
                                  setFieldValue(
                                    `shopInfo[${i}].addressAr`,
                                    makeRandomEmail(100)
                                  );
                                }}
                              />
                            </Form.Group>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Form>
              );
            }}
          </Formik>
        </Accordion>
        <Row>
          <Col xs={12}>
            <Button
              variant="primary"
              className={`${styles.addBranchBtn}`}
              onClick={() => {
                setBranchCounter(branchCounter + 1);
              }}
            >
              {t("addBranch")}
            </Button>
          </Col>
          <Col xs={12} className="mt-3 text-center">
            <Button
              type="submit"
              size="md"
              variant="primary"
              className={`${styles.saveBtn}`}
              form="addShopForm"
            >
              {t("save")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
