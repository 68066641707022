import React, { useState } from "react";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import styles from "./OwnerAcceptOtp.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changePassword } from "../../../../redux/features/TraderSlice";
import { useNavigate } from "react-router-dom";

export default function OwnerAcceptOtp(props) {
  const [message, setMessage] = useState("");

  const { t, i18n } = useTranslation();
  const token = useSelector((state) => state.cpc.user.login.token);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const verfCode = props.verfcode;
  let path = window.location.pathname;

  return (
    <div className={`${styles.OwnerAcceptOtpContent}`}>
      <Modal {...props} size="md" centered>
        <Modal.Body className={`${styles.giftPointBody}`}>
          <Formik
            initialValues={{
              otp: "",
            }}
            onSubmit={(values, actions) => {
              setMessage("");
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 3000);
              if (values.otp == verfCode) {
                if (path === "/trader/dashboard/change/password") {
                  const loadReq = toast.loading(t("loading"), {
                    transition: Zoom,
                    position:
                      i18n.language === "ar" ? "bottom-right" : "bottom-left",
                  });
                  dispatch(
                    changePassword({
                      oldPassword: props.changepasswordinfo.currentPassword,
                      newPassword: props.changepasswordinfo.newPassword,
                      token,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      navigate("/trader/dashboard/home");
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }
                if (path === "/owner/dashboard/change/password") {
                  const loadReq = toast.loading(t("loading"), {
                    transition: Zoom,
                    position:
                      i18n.language === "ar" ? "bottom-right" : "bottom-left",
                  });
                  dispatch(
                    changePassword({
                      oldPassword: props.changepasswordinfo.currentPassword,
                      newPassword: props.changepasswordinfo.newPassword,
                      token,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      navigate("/owner/dashboard/balance");
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }
              } else {
                setMessage("الكود الذي ادخلته غير صحيح");
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => checkKeyDown(e)}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Col xs={12}>
                      <Form.Label className={`${styles.regFormLabel}`}>
                        ادخل الكود
                      </Form.Label>
                    </Col>
                    <Col xs={12}>
                      <Form.Control
                        type="text"
                        className={`${styles.regFormInput}`}
                        name="otp"
                        id="otp"
                        onChange={(e) => {
                          setMessage("");
                          setFieldValue("otp", e.currentTarget.value);
                        }}
                        value={values.otp}
                      />
                    </Col>
                    {message && (
                      <Form.Text className="text-error">{message}</Form.Text>
                    )}
                  </Form.Group>
                  <div className="d-grid">
                    <Button
                      type="submit"
                      className={`${styles.okBtn}`}
                      variant="primary"
                      size="sm"
                      disabled={isSubmitting}
                    >
                      ارسال
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
