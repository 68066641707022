import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./ViewShops.module.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function ViewShops() {
  const [traders, setTraders] = useState([]);

  const token = useSelector((state) => state.cpc.user.login.token);

  const navigate = useNavigate();

  const { ownerID } = useParams();

  console.log(useParams())

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/traderList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        const filterTrader = res.data.traders.filter((value, trade, key) => {
          return value["shop_details"][0]["parent_merchant_id"] == ownerID;
        });
        console.log(filterTrader);
        setTraders(filterTrader);
      });
  }, []);

  return (
    <div className={`${styles.ViewShopsContent} p-3`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.shopTitle}`}>View Shops</h3>
          </Col>
          <Col xs={12}>
            {
              traders.length !== 0 ? (
                traders.map((trade) => {
                  return (
                    <div
                      className={`${styles.bgGrey} p-4 w-100 mb-3`}
                      key={Math.random() * 10000}
                    >
                      <Container>
                        <Row>
                          <Col xs={12} md={6}>
                            <div className={`${styles.infoContent}`}>
                              <h5>
                                {trade["shop_details"].map((tr) => tr.branchName)}
                              </h5>
                              <p className="d-inline me-3">
                                {trade["shop_details"].map((tr) => tr.address)}
                              </p>
                            </div>
                          </Col>
                          <Col xs={12} md={6}>
                            <div
                              className={`${styles.btnRegisterGroup} h-100  d-flex justify-content-center justify-content-md-end align-items-center`}
                            >
                              <Button
                                variant="primary"
                                className={`${styles.registerBtn}`}
                                onClick={() => {
                                  navigate(
                                    `/activator/dashboard/${ownerID}/editShops/${trade.id}`
                                  );
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  );
                })
              ) : (
                <h6 className="text-center">No Data</h6>
              )
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}
