import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  Badge,
  InputGroup,
} from "react-bootstrap";
import { HiFilter } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Team.module.scss";
import { Formik } from "formik";
import { handleLogout } from "../../../redux/features/userSlice";
import Pagination from "../../pagination/Pagination";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import i18n from "../../../i18n";
import i18next from "i18next";

export default function Team() {
  const [activators, setActivators] = useState([]);
  const [filterActivators, setFilterActivators] = useState([]);
  const [governId, setGovernId] = useState("");
  const [governorates, setGovernorates] = useState([]);
  const token = useSelector((state) => state.cpc.user.login.token);

  const [acts, setActs] = useState([]);

  const [t, i18n] = useTranslation();

  const [total, setTotal] = useState("");

  const [page, setPage] = useState(1);

  const [postsPerPage, setPostsPerPage] = useState(10);

  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(governId);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/reports/listUsers-modified", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const filteredActivators = res.data.data.filter((act) => {
            if (governId !== "") {
              return (
                act.CPCRole.roleName === "Activator" &&
                (act.firstName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                  act.lastName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  act.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
                  act.email
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) &&
                act.districts[0]["governorate_id"] == governId
              );
            } else {
              return (
                act.CPCRole.roleName === "Activator" &&
                (act.firstName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                  act.lastName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  act.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
                  act.email.toLowerCase().includes(searchValue.toLowerCase()))
              );
            }
          });
          setTotal(filteredActivators.length);
          const sortedActivators = filteredActivators.sort((a, b) => {
            return b.userID - a.userID;
          });
          setActivators(sortedActivators);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });

    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGovernorates(res.data["Governates List"]);
      });
  }, [governId, searchValue]);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listActivators", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          setActs(res.data.activators);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + postsPerPage;
  const currentPosts = activators.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * postsPerPage) % activators.length;
    setItemOffset(newOffset);
    Cookies.set("page", event.selected);
  };

  useEffect(() => {
    if (Cookies.get("page")) {
      const newOffsett =
        (Number(Cookies.get("page")) * postsPerPage) % activators.length;
      setItemOffset(newOffsett);
    } else {
      setItemOffset(0);
    }
  });

  console.log(activators);

  console.log(acts);

  return (
    <div className={`${styles.adminTeam} p-1`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              {t("activationTeam")}
            </h3>
          </Col>
          <Col xs={12} md={6}>
            <div className={`${styles.btnRight}`}>
              <Button
                size="md"
                variant="secondary"
                className={`${styles.reportBtn} me-2 mb-2`}
                onClick={() => {
                  navigate("/admin/dashboard/team/reports");
                }}
              >
                {t("activatorTeamReport")}
              </Button>
              <Button
                size="md"
                variant="primary"
                className="mb-2"
                onClick={() => {
                  navigate("/admin/dashboard/team/add");
                }}
              >
                {t("addActivator")}
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
              <div className={`${styles.filterForm} mb-3 mb-lg-0`}>
                <Formik
                  initialValues={{ governID: "" }}
                  onSubmit={(values) => {
                    setGovernId(values.governID);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.filterInput}`}>
                          <InputGroup.Text className={`${styles.filterLabel}`}>
                            <HiFilter />
                          </InputGroup.Text>
                          <Form.Select
                            size="sm"
                            name="governID"
                            id="governID"
                            onChange={(e) => {
                              setFieldValue("governID", e.currentTarget.value);
                              handleSubmit();
                            }}
                            className={`${styles.filterSelect}`}
                            value={values.governID}
                          >
                            <option value="">{t("gover")}</option>
                            {governorates.map((govern, index) => {
                              return (
                                <option key={index} value={govern.GovernateID}>
                                  {i18next.language == "ar"
                                    ? govern["governorate_name_ar"]
                                    : govern["governorate_name_en"]}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className={`${styles.searchForm}`}>
                <Formik
                  initialValues={{ searchValue: "" }}
                  onSubmit={(values) => {}}
                >
                  {({ values, touched, handleChange, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.searchInput}`}>
                          <InputGroup.Text className={`${styles.searchLabel}`}>
                            <BiSearch />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("search")}
                            className={`${styles.searchField}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => {
                              setItemOffset(0);
                              setSearchValue(e.currentTarget.value);
                            }}
                          />
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            {currentPosts.map((act) => {
              return (
                <div
                  className={`${styles.bgGrey} px-4 py-3 mb-3`}
                  key={Math.random() * 100000}
                >
                  <div
                    className={`${styles.activatorInfoContent} d-flex align-items-center`}
                  >
                    <img
                      src={require("../../../imgs/character.png")}
                      className="me-2"
                    />
                    <div className={`${styles.activatorInfo} mt-2`}>
                      <h5>{`${act.firstName} ${act.lastName}`}</h5>
                      <p>{`${act.phone}`}</p>

                      {acts.some((activ) => {
                        return activ.activatorId == act.userID;
                      }) == false ? (
                        <div className="d-flex flex-row mb-3">
                          <div
                            className={`${styles.activatedBadge} me-4 d-flex justify-content-center align-items-center flex-column`}
                          >
                            <Badge bg="primary">0</Badge>
                            {t("activated")}
                          </div>
                          <div
                            className={`${styles.assignedBadge} d-flex justify-content-center align-items-center flex-column`}
                          >
                            <Badge bg="light">0</Badge>
                            {t("assigned")}
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex flex-row mb-3">
                          <div
                            className={`${styles.activatedBadge} me-4 d-flex justify-content-center align-items-center flex-column`}
                          >
                            {acts
                              .filter((activv) => {
                                return activv.activatorId == act.userID;
                              })
                              .map((actt, index) => {
                                return (
                                  <Badge bg="primary" key={index}>
                                    {actt["assignedMerchants"].filter(
                                      (acttt) => {
                                        return acttt.activationStatus === 1;
                                      }
                                    ).length == actt["assignedMerchants"].length
                                      ? 0
                                      : actt["assignedMerchants"].filter(
                                          (acttt) => {
                                            return acttt.activationStatus === 1;
                                          }
                                        ).length}
                                  </Badge>
                                );
                              })}
                            {t("activated")}
                          </div>
                          <div
                            className={`${styles.assignedBadge} d-flex justify-content-center align-items-center flex-column`}
                          >
                            {acts
                              .filter((activv) => {
                                return activv.activatorId == act.userID;
                              })
                              .map((actt, index) => {
                                return (
                                  <Badge bg="light" key={index}>
                                    {actt["assignedMerchants"].filter(
                                      (acttt) => {
                                        return acttt.activationStatus === 1;
                                      }
                                    ).length == actt["assignedMerchants"].length
                                      ? 0
                                      : actt["assignedMerchants"].length}
                                  </Badge>
                                );
                              })}
                            {t("assigned")}
                          </div>
                        </div>
                      )}
                      {act.districts.map((dis) => {
                        return (
                          <h6
                            key={Math.random() * 10000}
                            className="d-inline me-2"
                          >
                            <Badge bg="light">
                              {`${dis["governorate"][t("governReport")]}, ${
                                dis["city"][t("cityReport")]
                              }`}
                            </Badge>
                          </h6>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={`${styles.activatorBtnContent} h-100 d-flex justify-content-center align-items-center mt-2`}
                  >
                    <Button
                      variant="primary"
                      className={`${styles.editBtn} me-2`}
                      onClick={() => {
                        navigate(`/admin/dashboard/team/edit/${act.userID}`);
                      }}
                    >
                      {t("edit")}
                    </Button>
                    <Button
                      variant="secondary"
                      className={`${styles.reportBtn}`}
                      onClick={() => {
                        navigate(`/admin/dashboard/team/report/${act.userID}`);
                      }}
                    >
                      {t("report")}
                    </Button>
                  </div>
                </div>
              );
            })}
          </Col>
          <Col xs={12}>
            <Pagination
              totalPosts={total}
              postsPerPage={postsPerPage}
              onChangePage={handlePageClick}
              currentPage={itemOffset}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
