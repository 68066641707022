import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import styles from "./StationPaymentReport.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function StationPaymentReport() {
  const navigate = useNavigate();
  const girdRef = useRef();

  const token = useSelector((state) => state.cpc.user.login.token);

  const { t } = useTranslation();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Business ID",
      field: "userId",
    },
    {
      headerName: "Phone Number",
      field: "phone",
    },
    {
      headerName: "Business Name",
      field: "businessName",
    },
    {
      headerName: "Amount",
      field: "Amount",
    },
    {
      headerName: "Points",
      field: "pointsUsed",
    },
    {
      headerName: "Governorate",
      field: "governorates",
      cellRenderer: (result) => {
        return (
          <div>
            {result.data["governorates"].map((gov) => {
              return gov["governorate_name_en"];
            })}
          </div>
        );
      },
    },
    {
      headerName: "District",
      field: "cities",
      cellRenderer: (result) => {
        return (
          <div>
            {result.data["cities"].map((city) => {
              return city["city_name_en"];
            })}
          </div>
        );
      },
    },
    {
      headerName: "Date & Time",
      field: "createdAt",
    },
  ]);

  const defaultColDef = {
    flex: 1,
    sortable: false,
    filter: false,
    floatingFilter: false,
    minWidth: 200,
  };

  const [rowData, setRowData] = useState([]);
  const [filter, setFilter] = useState({
    sort: "asc",
    sortBy: "createdAt",
    startDate: "",
    endDate: "",
    businessName: "",
    user_id: "",
  });

  useEffect(() => {
    axios
      .get(
        "https://evouchers.shareecoin.com/CPC/fuelStation/orders/getTotalAmountPerStation",
        {
          params: {
            sort: "asc",
            sortBy: "createdAt",
            startDate: "",
            endDate: "",
            businessName: "",
            user_id: "",
            limit: 10,
            page: "",
            offset: "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setRowData(res.data.data);
      });
  }, [filter]);

  return (
    <div className={`${styles.stationPaymentReport}`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div
              className="ag-theme-alpine ag-theme-quartz"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                // onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3 text-center">
            <Button
              variant="primary"
              className={`${styles.downloadBtn}`}
              onClick={() => {}}
            >
              {t("downloadReport")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
