import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import CustomerPoint from "../point/CustomerPoint";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import failedMessage from "../../../../messages/Failed";
import successMessage from "../../../../messages/Successful";
import { useDispatch, useSelector } from "react-redux";
import { earnPointsAfterScanQr } from "../../../../redux/features/ownerSlice";
import styles from "./CustomerQrscan.module.scss";
import { useTranslation } from "react-i18next";

export default function CustomerQrscan(props) {
  const [result, setResult] = useState("Loading ...");
  const [showGift, setShowGift] = useState(false);
  const [points, setPoints] = useState("");

  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);

  const { t, i18n } = useTranslation();

  const handleScan = (result, error) => {
    if (!!result) {
      setResult(result?.text);
      props.onHide();
      dispatch(
        earnPointsAfterScanQr({
          qrCode: result?.text,
          token,
        })
      ).then((res) => {
        const loadReq = toast.loading(t("loading"), {
          transition: Zoom,
          position: i18n.language === "ar" ? "bottom-right" : "bottom-left",
        });
        if (res.payload.data.statusCode === 200) {
          setPoints(res.payload.data.addedPoints);
          setShowGift(true);
          successMessage(
            res.payload.data.message,
            res.payload.data.messageAr,
            loadReq
          );
        } else {
          failedMessage(
            res.payload.data.error,
            res.payload.data.errorAr,
            loadReq
          );
        }
      });
    }
  };
  return (
    <div className={`${styles.qrScanContent}`}>
      <Modal {...props} size="md" centered>
        <Modal.Body className={`${styles.qrCodeModal}`}>
          <QrReader
            scanDelay={500}
            className={`${styles.qrVideo}`}
            onResult={handleScan}
            constraints={{ facingMode: "environment" }}
          />
          {/* <p className={`${styles.qrResult}`}>{result}</p> */}
        </Modal.Body>
      </Modal>
      <CustomerPoint
        show={showGift}
        onHide={() => {
          setShowGift(false);
        }}
        points={points}
      />
    </div>
  );
}
