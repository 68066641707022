import React, { useRef, useState } from "react";
import { Container, Row, Col, Badge, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { BiLoaderCircle } from "react-icons/bi";
import { handleLogout } from "../../../../../redux/features/userSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./RedeemReport.module.scss";
import { useTranslation } from "react-i18next";

export default function RedeemReport() {
  const girdRef = useRef();
  const [gridApi, setGridApi] = useState("");
  const token = useSelector((state) => state.cpc.user.login.token);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Reward Type",
      field: "rewardType",
      cellRenderer: (result) => {
        return (
          <div>{`${result.data["rewardType"]} - ${result.data["WalletVoucherName"]}`}</div>
        );
      },
      minWidth: 350,
    },
    {
      headerName: "Reward Value",
      field: "rewardValue",
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
    },
    {
      headerName: "Redeemed By",
      field: "redeemedBy",
    },
    {
      headerName: "Business Name",
      field: "businessName",
    },
    {
      headerName: "Order Date Time",
      field: "orderDateTime",
    },
    {
      headerName: "Redeem Date Time",
      field: "redeemDateTime",
    },
    {
      headerName: "Settled",
      field: "settled",
      cellRenderer: (res) => {
        if (res.data["settled"] === "false") {
          return (
            <div>
              <Badge bg="danger">Pending</Badge>
            </div>
          );
        } else {
          return (
            <div>
              <Badge bg="success">Delivered</Badge>
            </div>
          );
        }
      },
    },
  ]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .post(
        "https://evouchers.shareecoin.com/CPC/getReports",
        {
          reportType: "redeem report",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          let resultt = res.data.rewardsList.sort(function (a, b) {
            return new Date(b.orderDateTime) - new Date(a.orderDateTime);
          });
          params.api.applyTransaction({ add: resultt });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  };

  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: false,
    minWidth: 200,
  };

  const handleExport = () => {
    gridApi.exportDataAsCsv();
  };

  const { t } = useTranslation();

  return (
    <div className={`${styles.redeemReport}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/products");
                }}
              />
              {t("redeemReport")}
            </h3>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3 text-center">
            <Button
              variant="primary"
              className={`${styles.downloadBtn}`}
              onClick={() => {
                handleExport();
              }}
            >
              {t("downloadReport")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
