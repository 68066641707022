import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./AddStation.module.scss";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as yup from "yup";
import { Formik } from "formik";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { createStation } from "../../../../redux/features/adminSlice";

export default function AddStation() {
  const [cities, setCities] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [governID, setGovernID] = useState(null);
  const [activeCity, setAcitveCity] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectList, setSelectList] = useState([]);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGoverns(res.data["Governates List"]);
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: governID,
        Function: "ListCites",
      })
      .then((res) => {
        setCities(res.data["List of Cities"]);
      });
  }, [governID]);

  const createStationValidation = yup.object().shape({
    stationName: yup.string().required("Station name is required"),
    stationAddress: yup.string().required("Station address is required"),
    ownerPhoneNumber: yup.string().required("Owner phone number is required"),
    crNumber: yup.string().required("CR number is required"),
    govID: yup.string().required("Governorate is required"),
    cityID: yup.string().required("District is required"),
  });

  const token = useSelector((state) => state.cpc.user.login.token);

  return (
    <div className={`${styles.addStationContent} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations");
                }}
              />
              {t("addStation")}
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={{
              stationName: "",
              govID: "",
              ownerPhoneNumber: "",
              cityID: "",
              crNumber: "",
              stationAddress: "",
            }}
            onSubmit={(values, actions) => {
              const loadReq = toast.loading(t("loading"), {
                transition: Zoom,
                position:
                  i18next.language == "ar" ? "bottom-right" : "bottom-left",
              });
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 3000);
              dispatch(
                createStation({
                  token,
                  stationName: values.stationName,
                  stationAddress: values.stationAddress,
                  ownerPhoneNumber: values.ownerPhoneNumber,
                  crNumber: values.crNumber,
                  govID: values.govID,
                  cityID: values.cityID,
                })
              ).then((res) => {
                if (res.payload.data.statusCode === 200) {
                  navigate("/admin/dashboard/stations");
                  successMessage(
                    res.payload.data.message,
                    res.payload.data.messageAr,
                    loadReq
                  );
                } else {
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                }
              });
            }}
            validationSchema={createStationValidation}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("stationName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("stationName")}
                        className={`${styles.addInput}`}
                        name="stationName"
                        id="stationName"
                        onChange={handleChange}
                        value={values.stationName}
                      />
                      {errors.stationName && touched.stationName && (
                        <Form.Text className="text-error">
                          {errors.stationName}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("gover")}
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="govID"
                        id="govID"
                        onChange={(e) => {
                          setGovernID(e.currentTarget.value);
                          setFieldValue("govID", e.currentTarget.value);
                          setAcitveCity(true);
                        }}
                        value={values.govID}
                      >
                        <option value="">{t("selectGover")}</option>
                        {governs.map((gov) => {
                          return (
                            <option
                              key={gov.GovernateID}
                              value={gov.GovernateID}
                            >
                              {i18next.language == "ar"
                                ? gov["governorate_name_ar"]
                                : gov["governorate_name_en"]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("stationOwnerPhoneNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={11}
                        placeholder={t("stationOwnerPhoneNumber")}
                        className={`${styles.addInput}`}
                        name="ownerPhoneNumber"
                        id="ownerPhoneNumber"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.ownerPhoneNumber}
                      />
                      {errors.ownerPhoneNumber && touched.ownerPhoneNumber && (
                        <Form.Text className="text-error">
                          {errors.ownerPhoneNumber}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Col xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("districts")}
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="cityID"
                        id="cityID"
                        onChange={(e) => {
                          setFieldValue("cityID", e.currentTarget.value);
                        }}
                        value={values.cityID}
                        disabled={activeCity === false ? true : false}
                      >
                        <option value="">{t("selectDistrict")}</option>
                        {cities.map((city) => {
                          return (
                            <option key={city.cityID} value={city.cityID}>
                              {i18next.language == "ar"
                                ? city["city_name_Ar"]
                                : city["city_name_en"]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("crNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("crNumber")}
                        className={`${styles.addInput}`}
                        name="crNumber"
                        id="crNumber"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.crNumber}
                      />
                      {errors.crNumber && touched.crNumber && (
                        <Form.Text className="text-error">
                          {errors.crNumber}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("stationAddress")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("stationAddress")}
                        className={`${styles.addInput}`}
                        name="stationAddress"
                        id="stationAddress"
                        onChange={handleChange}
                        value={values.stationAddress}
                      />
                      {errors.stationAddress && touched.stationAddress && (
                        <Form.Text className="text-error">
                          {errors.stationAddress}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} className="mt-3 text-center">
                      <Button
                        type="submit"
                        size="md"
                        variant="primary"
                        className={`${styles.saveBtn}`}
                        disabled={isSubmitting}
                      >
                        {t("save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}
