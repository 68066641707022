import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import styles from "./ReportsHome.module.scss";
import StationOrdersReport from "../stationOrders/StationOrdersReport";
import StationPaymentReport from "../stationPayment/StationPaymentReport";
import { useTranslation } from "react-i18next";

export default function ReportsHome() {
  const navigate = useNavigate();
  const [activeReport, setActiveReport] = useState(1);

  const { t } = useTranslation();

  return (
    <div className={`${styles.reportsHomeContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations");
                }}
              />
              {t("stationsReport")}
            </h3>
          </Col>
          <Col xs={12} className="mb-4">
            <div className={`${styles.btnsContent}`}>
              <Button
                size="sm"
                variant="primary"
                className={`${
                  activeReport === 1
                    ? styles.activestationsReportBtn
                    : styles.notactiveStationReportBtn
                } m-2 `}
                onClick={() => {
                  setActiveReport(1);
                }}
              >
                {t("customerTransactions")}
              </Button>
              <Button
                size="sm"
                variant="primary"
                className={`${
                  activeReport === 2
                    ? styles.activestationsReportBtn
                    : styles.notactiveStationReportBtn
                } m-2 `}
                onClick={() => {
                  setActiveReport(2);
                }}
              >
                {t("stationsBills")}
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            {activeReport === 1 ? (
              <StationOrdersReport />
            ) : (
              <StationPaymentReport />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
