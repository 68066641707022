import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { act } from "react-dom/test-utils";

export const createCustomer = createAsyncThunk(
  "create/customer",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/create/user",
        {
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phoneNumber,
          email: data.email,
          password: data.password,
          districts: [
            {
              govID: data.govID,
              cityID: data.cityID,
            },
          ],
          role_id: 5,
          gender: data.gender,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const saveCarPlate = createAsyncThunk(
  "customer/save/carplate",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/car/platteNumber",
        {
          store_plate_number: true,
          plate_number_letters: data.carplateLetter,
          plate_number_numbers: data.carplateNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const sendFuelRequest = createAsyncThunk(
  "customer/fuel/request",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/payment/sendCode",
        {
          voucher_denomination: data.fuelValue,
          plate_number_letters: data.carplateLetter,
          plate_number_numbers: data.carplateNumber,
          station_id: data.stationID,
          operator_id: data.oberatorID,
          voucher_type: data.vouchertype,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const ownerSendFuelRequest = createAsyncThunk(
  "owner/fuel/request",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/payment/sendCode",
        {
          voucher_denomination: data.fuelValue,
          plate_number_letters: data.carplateLetter,
          plate_number_numbers: data.carplateNumber,
          station_id: data.stationID,
          operator_id: data.oberatorID,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const handleStations = createAsyncThunk(
  "stations/choose",
  async (data, rejectWithValue) => {
    try {
      const result = axios.get(
        "https://evouchers.shareecoin.com/CPC/fuelStation/stations",
        {
          params: {
            governorateID: data.stationGov,
            cityID: data.stationCity,
            sort: "asc",
          },
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const confirmRequestCode = createAsyncThunk(
  "/customer/confirm",
  async (data, rejectWithValue) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/payment/confirm",
        {
          confirmation_code: data.confirmCode,
          transaction_id: data.transID,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const verifyFuelVoucher = createAsyncThunk(
  "verify/voucher",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `https://evouchers.shareecoin.com/CPC/verify/fuel-voucher/${data.voucherCode}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const cancelFuelVoucher = createAsyncThunk(
  "cancel/voucher",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const result = await axios.patch(
        `https://evouchers.shareecoin.com/CPC/cancel/fuel-voucher/${data.voucherCode}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const redeemFuelVoucher = createAsyncThunk(
  "redeem/fuelVoucher",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const result = await axios.patch(
        `https://evouchers.shareecoin.com/CPC/redeem/fuel-voucher`,
        {
          fuelVouchercode: data.voucherCode,
          transactionId: data.transID,
          confirmationCode: data.confirmCode,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const getPointsValue = createAsyncThunk(
  "customer/points/value",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        "https://evouchers.shareecoin.com/CPC/redeem/points",
        {
          params: {
            amount: data.amount,
            type: data.cashType,
          },
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const bankCashout = createAsyncThunk(
  "bank/cashout",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/redeem/cashout/bank",
        {
          issuer: "bank_card",
          amount: data.amount,
          points: data.points,
          full_name: data.fullName,
          bank_card_number: data.cardNumber,
          bank_code: data.bankCode,
          bank_transaction_type: "cash_transfer",
          user_password: data.password,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const walletMobileCashout = createAsyncThunk(
  "wallet/cashout",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/redeem/cashout/wallet",
        {
          issuer: data.walletName,
          amount: data.cashAmount,
          points: data.points,
          full_name: data.fullName,
          user_password: data.password,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);
export const walletBankCashout = createAsyncThunk(
  "wallet/cashout",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/redeem/cashout/wallet",
        {
          bank_code: data.bankCode,
          issuer: "bank_wallet",
          cashoutAmount: Number(data.cashAmount),
          points: data.points,
          full_name: data.fullName,
          user_password: data.password,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const mezaPrepaidCashout = createAsyncThunk(
  "prepaid/cashout",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/redeem/cashout/bank",
        {
          issuer: "bank_card",
          amount: data.amount,
          points: data.points,
          full_name: data.fullName,
          bank_card_number: data.cardNumber,
          bank_code: data.bankCode,
          bank_transaction_type: "prepaid_card",
          user_password: data.password,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const customerSendOTP = createAsyncThunk(
  "customer/send/otp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://evouchers.shareecoin.com/CPC/customer/sendOTP`,
        null,
        {
          params: {
            mobileNumber: data.mobileNumber,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: {
    [sendFuelRequest.fulfilled]: (state, action) => {
      console.log(action);
    },
  },
});

export const { handleOTP } = customerSlice.actions;

export default customerSlice.reducer;
