import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./MerchantListingReport.module.scss";
import { handleLogout } from "../../../../redux/features/userSlice";
import { DownloadTableExcel } from "react-export-table-to-excel";

export default function MerchantListingReport() {
  const girdRef = useRef();
  const [gridApi, setGridApi] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);
  const { t } = useTranslation();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: t("businessName"),
      field: "businessName",
      flex: 2,
    },
    {
      headerName: t("firstName"),
      field: "firstName",
    },
    {
      headerName: t("lastName"),
      field: "lastName",
    },
    {
      headerName: t("phoneNumber"),
      field: "phone",
    },
    {
      headerName: t("email"),
      field: "email",
      minWidth: 350,
    },
    {
      headerName: t("gover"),
      colId: "govern",
      field: "districts",
      cellRenderer: (result) => {
        return (
          <div>
            {result.data.districts.map((dis) => {
              return <div>{`${dis["governorate"][t("governReport")]}`}</div>;
            })}
          </div>
        );
      },
    },
    {
      headerName: t("city"),
      field: "districts",
      colId: "city",
      cellRenderer: (result) => {
        console.log(result.data.districts);
        return (
          <div>
            {result.data.districts.map((dis) => {
              return <div>{`${dis["city"][t("cityReport")]}`}</div>;
            })}
          </div>
        );
      },
    },
    {
      headerName: t("activationStatus"),
      field: "activation_status",
    },
    {
      headerName: t("assignStatus"),
      field: "assigment_status",
    },
    {
      headerName: t("activatorNamee"),
      field: "activator_name",
    },
  ]);

  const dispatch = useDispatch();

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .get(
        "https://evouchers.shareecoin.com/CPC/reports/listUsers-modified-2",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          const filterdOwners = res.data.data.filter(
            (own) => own.role === "Owner"
          );
          params.api.applyTransaction({ add: filterdOwners });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  };

  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: false,
    minWidth: 200,
  };

  const navigate = useNavigate();

  const handleExport = () => {
    gridApi.exportDataAsCsv({
      fileName: "Merchants-Listing-Report",
      processCellCallback: function (params) {
        if (params.column.getColId() === "govern") {
          return params.value[0]["governorate"]["governorate_name_en"];
        }
        if (params.column.getColId() === "city") {
          return params.value[0]["city"]["city_name_en"];
        }
        return params.value;
      },
    });
  };

  return (
    <div className={`${styles.MerchantListingReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/merchants/all");
                }}
              />
              {t("listingMerchantsReport")}
            </h3>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                enableRtl={true}
                ref={girdRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3 text-center">
            <Button
              variant="primary"
              className={`${styles.downloadBtn}`}
              onClick={() => {
                handleExport();
              }}
            >
              {t("downloadReport")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
