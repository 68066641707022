import React, { Fragment, useEffect, useState } from "react";
import CustomerPagination from "../../../pagination/customerPagination/CustomerPagination";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdCancel } from "react-icons/md";
import styles from "./FuelVouchers.module.scss";
import { useDispatch, useSelector } from "react-redux";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { cancelFuelVoucher } from "../../../../redux/features/customerSlice";
import { useTranslation } from "react-i18next";

export default function FuelVouchers(props) {
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [showOption, setShowOption] = useState(1);
  const [vouchers, setVouchers] = useState([]);
  const [totalVouchers, setTotalVouchers] = useState("");

  let fuelVouchers = props.fuelvouchers;

  console.log(fuelVouchers);

  useEffect(() => {
    if (showOption === 1) {
      let activeVouchers = fuelVouchers.filter(
        (vouch) => vouch["expired"] !== true || vouch["redeemed"] !== true
      );

      setVouchers(activeVouchers);
      setTotalVouchers(activeVouchers.length);
    } else {
      let expiredVouchers = fuelVouchers.filter(
        (vouch) => vouch["expired"] === true || vouch["redeemed"] === true
      );
      setVouchers(expiredVouchers);
      setTotalVouchers(expiredVouchers.length);
    }
  }, [showOption]);

  console.log(fuelVouchers);

  const postLastIndex = page * postsPerPage;
  const postFirstIndex = postLastIndex - postsPerPage;
  const currentPosts = vouchers.slice(postFirstIndex, postLastIndex);

  const [copyMessage, setCopyMessage] = useState(false);
  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);

  const { t, i18n } = useTranslation();

  const handleCancelVoucher = (code) => {
    dispatch(
      cancelFuelVoucher({
        voucherCode: code,
        token,
      })
    ).then((res) => {
      const loadReq = toast.loading(t("loading"), {
        transition: Zoom,
        position: i18n.language === "ar" ? "bottom-right" : "bottom-left",
      });
      console.log(res);
      if (res.payload.data.statusCode === 200) {
        successMessage(
          res.payload.data.message,
          res.payload.data.messageAr,
          loadReq
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        failedMessage(
          res.payload.data.error,
          res.payload.data.errorAr,
          loadReq
        );
      }
    });
  };

  return (
    <div className={`${styles.giftCardsParent} p-4`}>
      <h3 className={`${styles.teamTitle} mb-3`}>كروت الوقود</h3>
      <div>
        <div
          className={`${styles.fuelTypeContent} mb-4 d-flex justify-content-around`}
        >
          <Button
            variant="danger"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 1 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(1);
            }}
          >
            صالح
          </Button>
          <Button
            variant="danger"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 2 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(2);
            }}
          >
            مستخدم
          </Button>
        </div>
      </div>
      <div className="mb-4 d-flex flex-wrap justify-content-center align-items-center">
        {currentPosts.map((reward, index) => {
          return (
            <div className={`${styles.giftCard} m-2 p-3`} key={index}>
              {reward["redeemed"] === true || reward["expired"] === true ? (
                <div className={`${styles.notActiveCard}`}></div>
              ) : null}
              <div
                className={`${styles.cancelIconContent}`}
                onClick={() => {
                  handleCancelVoucher(reward["voucher_code"]);
                }}
              >
                <MdCancel />
              </div>
              <div className={`${styles.giftCardInfo} w-100`}>
                <div className={`${styles.giftCardImg} me-2`}>
                  <img src={require("../../../../imgs/fuellogo.jpeg")} />
                </div>
                <div className={`${styles.giftCardDetails}`}>
                  <p>كارت وقود</p>
                  <p>كود:</p>
                </div>
                <div className={`${styles.giftCardDetails}`}>
                  <p>{reward.denomination} جنيه</p>
                  <p>{reward["voucher_code"]}</p>
                </div>
              </div>
              <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                <CopyToClipboard
                  text={reward["voucher_code"]}
                  // onCopy={}
                  onCopy={() => {
                    let targetButton = document.getElementById(
                      `copycode-${index}`
                    );
                    let ButtonText = targetButton.innerText;
                    targetButton.disabled = true;
                    targetButton.innerText = "تم نسخ الكود";
                    setTimeout(() => {
                      targetButton.disabled = false;
                      targetButton.innerHTML = ButtonText;
                    }, 1500);
                  }}
                >
                  <Button
                    variant="danger"
                    size="sm"
                    id={`copycode-${index}`}
                    className={`${styles.getCodeBtn} mx-1`}
                    disabled={
                      reward["redeemed"] === true || reward["expired"] === true
                        ? true
                        : false
                    }
                  >
                    انسخ كود الكارت
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          );
        })}
      </div>
      <CustomerPagination
        totalPosts={vouchers.length}
        postsPerPage={postsPerPage}
        onChangePage={(value) => {
          setPage(value);
        }}
        currentPage={page}
      />
    </div>
  );
}
