import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import styles from "./OwnerReport.module.scss";
import { useTranslation } from "react-i18next";

export default function OwnerReport() {
  const girdRef = useRef();
  const [gridApi, setGridApi] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);
  const merchantID = useSelector((state) => state.cpc.user.login.merchantID);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Governorate",
      field: "governorate_name_ar",
    },
    {
      headerName: "District",
      field: "city_name_ar",
    },
    {
      headerName: "QR Scanned",
      field: "scannedQR",
    },
    {
      headerName: "Earned Rewards",
      field: "earnedRewards",
    },
  ]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .post(
        "https://evouchers.shareecoin.com/CPC/getReports",
        {
          reportType: "owner reports",
          merchantId: merchantID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        params.api.applyTransaction({ add: res.data.scannedQRs });
      });
  };

  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: false,
    minWidth: 200,
  };

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleExport = () => {
    gridApi.exportDataAsCsv();
  };
  return (
    <div className={`${styles.OwnerReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/owner/dashboard/balance");
                }}
              />
              {t("report")}
            </h3>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                dir="ltr"
              />
            </div>
          </Col>
          <Col xs={12} className="my-3 text-center">
            <Button
              variant="primary"
              className={`${styles.downloadBtn}`}
              onClick={() => {
                handleExport();
              }}
            >
              {t("downloadReport")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
