import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { createOperator } from "../../../../redux/features/stationSlice";
import styles from "./AddOperator.module.scss";

export default function AddOperator() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const token = useSelector((state) => state.cpc.user.login.token);

  const addOperatorValidation = yup.object().shape({
    operatorName: yup.string().required("Operator name is required"),
    operatorPhoneNumber: yup
      .string()
      .required("Operator phone number is required"),
  });

  return (
    <div className={`${styles.addOperatorContent} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations");
                }}
              />
              {t("addOperator")}
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={{
              operatorName: "",
              operatorPhoneNumber: "",
            }}
            onSubmit={(values, actions) => {
              console.log(values);
              const loadReq = toast.loading(t("loading"), {
                transition: Zoom,
                position:
                  i18next.language == "ar" ? "bottom-right" : "bottom-left",
              });
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 3000);
              dispatch(
                createOperator({
                  token,
                  operatorName: values.operatorName,
                  operatorPhoneNumber: values.operatorPhoneNumber,
                })
              ).then((res) => {
                console.log(res);
                if (res.payload.data.statusCode === 200) {
                  navigate("/stationOwner/dashboard/home");
                  successMessage(
                    res.payload.data.message,
                    res.payload.data.messageAr,
                    loadReq
                  );
                } else {
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                }
              });
            }}
            validationSchema={addOperatorValidation}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("operatorName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("operatorName")}
                        className={`${styles.addInput}`}
                        name="operatorName"
                        id="operatorName"
                        onChange={handleChange}
                        value={values.operatorName}
                      />
                      {errors.operatorName && touched.operatorName && (
                        <Form.Text className="text-error">
                          {errors.operatorName}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("operatorNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={11}
                        placeholder={t("operatorNumber")}
                        className={`${styles.addInput}`}
                        name="operatorPhoneNumber"
                        id="operatorPhoneNumber"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.operatorPhoneNumber}
                      />
                      {errors.operatorPhoneNumber &&
                        touched.operatorPhoneNumber && (
                          <Form.Text className="text-error">
                            {errors.operatorPhoneNumber}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} className="mt-3 text-center">
                      <Button
                        type="submit"
                        size="md"
                        variant="primary"
                        className={`${styles.saveBtn}`}
                        disabled={isSubmitting}
                      >
                        {t("save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}
