import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaExchangeAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./RedeemPoints.module.scss";
import GiftCards from "./giftcards/GiftCards";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import OwnerCashout from "./cashout/OwnerCashout";
import OwnerFuel from "./fuel/OwnerFuel";

export default function RedeemPoints() {
  const [showOption, setShowOption] = useState(1);
  const [step, setStep] = useState("");

  const [traderBalance, setTraderBalance] = useState([]);
  const [totalBalance, setTotalBalance] = useState({
    points: "",
    egp: "",
  });

  const stepback = (step) => {
    setStep(step);
  };

  const { t } = useTranslation();

  const token = useSelector((state) => state.cpc.user.login.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/customer/pointsBalance", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          setTotalBalance({
            points: res.data.totalPointsBalance,
            egp: res.data.totalPointsBalanceEGP,
          });
          setTraderBalance(res.data.traderPointsBalances);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  return (
    <div className={`${styles.redeemPointsContent}`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  if (step == 2) {
                    stepback(1);
                  } else {
                    navigate("/owner/dashboard/balance");
                  }
                }}
              />
              {t("redeemPoints")}
            </h3>
          </Col>
          <Col xs={12} sm={8}>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <Button
                variant="primary"
                size="sm"
                className={`${styles.redeemPointsBtn} me-2 ${
                  showOption === 1 && styles.redeemPointActiveBtn
                }`}
                onClick={() => {
                  setShowOption(1);
                }}
              >
                {t("giftCards")}
              </Button>
              <Button
                variant="primary"
                size="sm"
                className={`${styles.redeemPointsBtn} me-2 ${
                  showOption === 2 && styles.redeemPointActiveBtn
                }`}
                onClick={() => {
                  setShowOption(2);
                }}
              >
                {t("cashout")}
              </Button>
              <Button
                variant="primary"
                size="sm"
                className={`${styles.redeemPointsBtn} me-2 ${
                  showOption === 3 && styles.redeemPointActiveBtn
                }`}
                onClick={() => {
                  setShowOption(3);
                }}
              >
                {t("fuel")}
              </Button>
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className={`${styles.resultContent} mt-3 d-flex align-items-center`}
              >
                <div>
                  <h5 className="text-center">Points</h5>
                  <p className="text-center">{totalBalance.points}</p>
                </div>
                <div className="mx-3 text-center">
                  <FaExchangeAlt />
                </div>
                <div>
                  <h5 className="text-center">EGP</h5>
                  <p className="text-center">{totalBalance.egp}</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3">
            {showOption === 1 ? (
              <GiftCards stepback={stepback} stepnow={step} />
            ) : showOption === 2 ? (
              <OwnerCashout />
            ) : (
              showOption === 3 && <OwnerFuel />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
